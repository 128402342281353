import { toast } from 'react-toastify';
import { Howl } from 'howler';
import assetJoin from '../assets/join.mp3';
import assetLeave from '../assets/leave.mp3';
import { apiClient } from './ApiClient';

const audioJoin = new Howl({
  src: [assetJoin],
  volume: 0.8,
});

const audioLeave = new Howl({
  src: [assetLeave],
  volume: 0.8,
});

const handleError = (err, message) => {
  console.error(err);
  toast(message, {
    type: toast.TYPE.ERROR,
  });
};

const initializeVideoControls = (
  channel,
  streamName,
  token,
  screenShareEnabled,
  onSubscriberConnected,
  onSubscriberDisconnected,
  t,
  session,
  isOrganizer
) => {
  const preferences = apiClient.getUserPreferencesFromLocalStorage();
  const fitMode = preferences?.video?.fitMode;

  session.setOnSubscriberConnected(() => {
    onSubscriberConnected();
    audioJoin.play();
  });

  session.setOnSubscriberDisconnected(() => {
    onSubscriberDisconnected();
    audioLeave.play();
  });

  const publisher = session.initPublisher('publisher', {
    fitMode,
    name: streamName,
  });

  const nativeSession = session.getNativeSession();

  // Subscribe to a newly created stream
  nativeSession.on('streamCreated', (event) => {
    // ignore screen share streams
    if (event.stream.videoType === 'screen') {
      return;
    }

    session.subscribe(
      event.stream,
      'subscribers',
      { fitMode },
      (e) =>
        e && isOrganizer && handleError(e, t('videoSubscriptionGeneralError'))
    );
  });

  // Connect to the session
  session
    .connect(token)
    .then(() => {
      nativeSession.publish(publisher);
      if (isOrganizer) {
        const roomMetaData = {
          organizer: { streamId: publisher.streamId },
          screenShareEnabled,
        };
        channel.broadcastRoomMetaData(roomMetaData);
      }
    })
    .catch((err) => {
      handleError(err, t('videoSessionGeneralError'));
    });

  const result = {
    publishVideo: (publish) => {
      publisher.publishVideo(publish);
    },
    publishAudio: (publish) => {
      publisher.publishAudio(publish);
    },
  };

  const cameraPromise = session
    .getCycleVideoCallback(publisher)
    .then((callback) => {
      result.cycleVideo = callback;
      return result;
    });

  return {
    destructor: () => publisher.destroy(),
    camera: cameraPromise,
  };
};

export { initializeVideoControls };
