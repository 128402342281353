import React from 'react';
import { Label } from '../atoms';
import Store from '../services/Store';
import { apiClient } from '../services/ApiClient';

const SESSION_NOTES = '$sessionNotes';

const Notes = () => {
  Store.sessionNotes = apiClient.readFromLocalStorage(SESSION_NOTES);

  const handleSessionNotesChange = (event) => {
    Store.sessionNotes = event.currentTarget.value;
    apiClient.saveToLocalStorage(SESSION_NOTES, Store.sessionNotes);
  };

  return (
    <section className="column h100">
      <b>
        <Label keyId="notes" />
      </b>
      <textarea
        className="h100"
        defaultValue={Store.sessionNotes}
        onChange={handleSessionNotesChange}
        style={{ height: '20rem', maxWidth: '100%', resize: 'none' }}
      />
    </section>
  );
};

export default Notes;
