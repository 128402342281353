import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { apiClient } from '../../services/ApiClient';
import FormViewerOrganizerToolbar from './FormViewerOrganizerToolbar';
import FormViewerAttendeeToolbar from './FormViewerAttendeeToolbar';
import { ComChannelContext } from '../../services/ComChannel';
import './FormViewer.scss';
import SessionEventType from '../../services/SessionEventType';

const FormViewer = ({
  room,
  formData,
  attendeeId,
  onAttendeeHasSubmittedForm,
  onClose,
}) => {
  const isOrganizer = !!onAttendeeHasSubmittedForm;
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});

  const channel = useContext(ComChannelContext);
  const { t } = useTranslation();

  const handleFormClose = useCallback(() => {
    channel.broadcastOrganizerFormClose(formData);
    onClose();
  }, [onClose, channel, formData]);

  useEffect(() => {
    const roomId = room && room.id;
    const handleWindowMessage = (event) => {
      try {
        const data =
          event.data &&
          typeof event.data === 'string' &&
          event.data.indexOf('formViewer') > 0 &&
          JSON.parse(event.data);
        if (data) {
          if (
            data.namespace === 'formViewer' &&
            data.eventName === 'valueChanged'
          ) {
            channel.broadcastFormViewerValueChange(data.state);
            setInputData(data.state);
          }
        }
      } catch (e) {
        console.error(`handleWindowMessage Error:${JSON.stringify(e)}`);
      }
    };

    // Set data
    if (formData && formData.html) {
      const frame = document.getElementById('frame');
      frame.src = 'about:blank';
      const doc = frame.contentWindow.document;
      doc.open();
      doc.write(formData.html);
      doc.close();
      setLoading(false);
      window.addEventListener('message', handleWindowMessage);
    }

    // Init communication
    const valueChangedUnsubscribe = channel.onFormViewerValueChanged(
      (event) => {
        const frame = document.getElementById('frame');
        frame.contentWindow.postMessage(event, '*');
        setInputData(event);
      }
    );
    const formCanceledUnsubscribe = channel.onFormViewerAttendeeCanceled(() => {
      if (isOrganizer) {
        handleFormClose();
        toast(t('formCancelled'), {
          type: toast.TYPE.INFO,
        });
      }
    });
    const formSubmittedUnsubscribe = channel.onFormViewerAttendeeSubmitted(
      (event) => {
        if (isOrganizer) {
          const frame = document.getElementById('frame');
          const html = frame.contentWindow.document.documentElement.outerHTML;
          onAttendeeHasSubmittedForm(event.data, html, attendeeId)
            .then(() => {
              const eventData = {
                attendees: [attendeeId],
                formId: formData.id,
                relatedSessionId: channel.roomInfo.sessionId,
              };
              return apiClient.addSessionEvent(
                roomId,
                SessionEventType.FORM_SUBMITTED,
                eventData
              );
            })
            .finally(handleFormClose);
        }
      }
    );

    return () => {
      if (valueChangedUnsubscribe) {
        valueChangedUnsubscribe();
      }

      if (formCanceledUnsubscribe) {
        formCanceledUnsubscribe();
      }

      if (formSubmittedUnsubscribe) {
        formSubmittedUnsubscribe();
      }

      window.removeEventListener('message', handleWindowMessage);
    };
  }, [
    attendeeId,
    channel,
    formData,
    handleFormClose,
    isOrganizer,
    onAttendeeHasSubmittedForm,
    room,
    t,
  ]);

  return (
    <div data-testid="form_viewer" className="form-viewer">
      <div className="form-viewer-wrapper">
        {loading && (
          <div className="loader-full-size">
            <div className="simple-loader simple-loader-small" />
          </div>
        )}

        <div className="form-viewer-title">{formData.name}</div>

        <div className="priority form-viewer-content">
          <iframe
            data-testid="form_viewer_frame"
            id="frame"
            src="about:blank"
            title="Form"
          />
        </div>

        {isOrganizer && (
          <FormViewerOrganizerToolbar onClose={handleFormClose} />
        )}

        {!isOrganizer && <FormViewerAttendeeToolbar inputData={inputData} />}
      </div>
    </div>
  );
};

export default FormViewer;
