import React, { forwardRef, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SampleFormGenerator from './SampleFormGenerator';
import FormViewer from '../FormViewer/FormViewer';
import { ComChannelContext } from '../../services/ComChannel';
import { apiClient } from '../../services/ApiClient';
import Store from '../../services/Store';

const SampleWizard = forwardRef(({ room, attendees, onCancel }, ref) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(null);
  const attendee = attendees[0];
  const channel = useContext(ComChannelContext);

  const handleOnGenerateFormFinish = (data) => {
    setFormData(data);
    channel.broadcastOrganizerFormOpen(data);
  };

  const recordSampleDropInSession = (attendeeId) => {
    const data = Store.attendeesWithSampleDropSubmitted;
    if (data) {
      data.push(attendeeId);
    } else {
      Store.attendeesWithSampleDropSubmitted = [attendeeId];
    }
  };

  const handleOnAttendeeHasSubmittedForm = (
    formSubmitData,
    formHtml,
    attendeeId
  ) => {
    const sampleId = formData.id;
    return apiClient
      .submitSampleDrop(sampleId, formSubmitData, formHtml)
      .then((result) => {
        toast.success(t('formSubmitted'));
        return apiClient.transferSampleDrop(result.data.submitSampleDrop.id);
      })
      .then(() => {
        recordSampleDropInSession(attendeeId);
        toast.success(t('formInstanceTransferred'));
      });
  };

  return (
    <div>
      {!formData && (
        <div ref={ref} className="card vw-35">
          <SampleFormGenerator
            roomId={room.id}
            attendees={attendees}
            onCancel={onCancel}
            onFinish={handleOnGenerateFormFinish}
          />
        </div>
      )}
      {formData && (
        <div className="card">
          <FormViewer
            attendeeId={attendee.id}
            room={room}
            formData={formData}
            onAttendeeHasSubmittedForm={handleOnAttendeeHasSubmittedForm}
            onClose={onCancel}
          />
        </div>
      )}
    </div>
  );
});

export default SampleWizard;
