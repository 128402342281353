import Store from '../services/Store';
import featureEnabled, { features } from '../services/FeatureFlag';

export const trackMediaSequence = (page) => {
  if (featureEnabled(features.MEDIA_TIME_TRACKING) && Store.mediaTracking) {
    const latestSequence =
      Store.mediaTracking?.trackingInfo[
        Store.mediaTracking.trackingInfo.length - 1
      ];

    if (typeof latestSequence !== 'undefined') {
      Store.mediaTracking.trackingInfo.push({
        sequence: latestSequence.sequence + 1,
        page,
        startTime: new Date(),
      });
    }
  }
};
