import gql from 'graphql-tag';

const roomPayload = `
    id
    name
    ownerName
    startTime
    endTime
    code
    state
    roomType {
      id
      name
      defaultRoomType
      screenSharingEnabled
    }
    media {
      id
      name
      contentDocument {
        id
        name
        mediaType
        downloadUrl
        uploadUrl
      }
      thumbnailDocument {
        id
        name
        mediaType
        downloadUrl
        uploadUrl
      }
    }
    forms {
      id
      name
    }
    attendees {
      id
      displayName
      attendeeData {
        id
        displayName
        phone
        fax
        mobile
        email
        website
        organizations {
          id
          displayName
          function
          address
          postalCode
          city
          phone
          fax
          mobile
          email
          website
        }
        network {
          nodes {
            id
            displayName
            type
          }
          edges {
            id
            type
            source
            target
          }
        }
        touchPoints {
          id
          displayName
          date
          organizer
          type
          topics
          description
        }
        blocks {
          name
          values
        }
      }
    }
`;

const roomSmallPayload = `
    id
    name
    ownerName    
    attendees {
      id
      displayName
    }
    startTime
    endTime
    code
    state
`;

const initRoomMutation = (isoStartTime, name) => {
  return gql`
        mutation($attendees: [String!]!) {
            initRoom(attendees: $attendees, startTime: "${isoStartTime}", name: "${name}") {
                ${roomSmallPayload}
            }
        }`;
};

const completeRoomMutation = (roomId, sessionMedia, sessionNotes) => {
  return gql`
        mutation {
            completeRoom( roomId: "${roomId}", sessionMedia: ${sessionMedia}, sessionNotes: """${sessionNotes}""" ) {
                id
                state
            }
        }
    `;
};

const cancelRoomMutation = (roomId) => {
  return gql`
        mutation {
            cancelRoom( roomId: "${roomId}" ) {
                id
                state
            }
        }
    `;
};

const transferRoomSession = (roomId) => {
  return gql`
        mutation {
            transferRoomSession( roomId: "${roomId}" ) {
                externalId
            }
        }
    `;
};

const roomQuery = (roomId) => gql`
    query {
        room(id: "${roomId}") {
            ${roomPayload}
        }
    }
`;

const roomsQuery = () => {
  return gql`
        query($attendees: [String!]!, $states: [String!]!) {
            rooms(attendees: $attendees, states: $states) {
                data {
                    ${roomSmallPayload}
                }
            }
        }`;
};

const attendeeDataQuery = () => {
  return gql`
    query ($attendeeIds: [String!]!) {
      attendeeData(ids: $attendeeIds) {
        id
        displayName
        email
      }
    }
  `;
};

const updateRoomAttendeeMutation = () => {
  return gql`
    mutation ($attendee: AttendeeDataInput!) {
      updateRoomAttendee(attendeeDataInput: $attendee)
    }
  `;
};

const attendeeDataList = () => {
  return gql`
    query ($search: String, $offset: Int, $isColleague: Boolean) {
      attendeeList(
        search: $search
        offset: $offset
        isColleague: $isColleague
      ) {
        id
        displayName
        email
        isColleague
      }
    }
  `;
};

const sendLinkMutation = (roomId, attendeeId, alternateEmail) => {
  if (attendeeId) {
    return gql`
            mutation {
                sendLinkMail(roomId: "${roomId}", attendeeId: "${attendeeId}", alternateEmail: "${alternateEmail}")
            }`;
  }
  return gql`
            mutation {
                sendLinkMail(roomId:"${roomId}", alternateEmail: "${alternateEmail}")
            }`;
};

const personalizeFormQuery = (roomId, formId, attendeeId) => {
  if (attendeeId) {
    return gql`
            query {
                personalizeForm(roomId: "${roomId}", formId: "${formId}", attendeeId: "${attendeeId}")
            }`;
  }
  return gql`
            query {
                personalizeForm(roomId: "${roomId}", formId: "${formId}")
            }`;
};

const storeFormInstanceMutation = () => {
  return gql`
    mutation ($formInstance: FormInstanceInput!) {
      storeFormInstance(formInstance: $formInstance) {
        id
      }
    }
  `;
};

const transferFormInstance = (formInstanceId) => {
  return gql`
        mutation {
            transferFormInstance(formInstanceId: "${formInstanceId}") {
                id
            }
        }`;
};

const addSessionEvent = () => {
  return gql`
    mutation ($sessionEvent: SessionEventAddInput!) {
      addSessionEvent(sessionEvent: $sessionEvent) {
        roomId
        type
      }
    }
  `;
};

const samples = () => {
  return gql`
    query ($roomId: String!, $attendeeId: String!) {
      samples(roomId: $roomId, attendeeId: $attendeeId) {
        roomId
        attendeeId
        allowedSamples {
          id
          externalId
          name
          batches {
            id
            externalId
            name
            expiryDate
            maxQuantity
            minQuantity
          }
          maxTotalQuantity
          minTotalQuantity
        }
      }
    }
  `;
};

export {
  initRoomMutation,
  completeRoomMutation,
  cancelRoomMutation,
  transferRoomSession,
  roomQuery,
  roomsQuery,
  attendeeDataQuery,
  updateRoomAttendeeMutation,
  attendeeDataList,
  sendLinkMutation,
  personalizeFormQuery,
  storeFormInstanceMutation,
  transferFormInstance,
  addSessionEvent,
  samples,
};
