const LOCAL_ATTENDEE_URL = 'http://localhost:3091';
const LOCAL_ADMIN_URL = 'http://localhost:3001';
const ORGANIZER_URL_PREFIX = 'organizer-';
const ADMIN_URL_PREFIX = 'admin-';
const OPENVIDU_URL_SUFFIX = '-ov';

export const isLocalHost = () => {
  const { hostname } = window.location;
  const host = hostname.split('.')?.[0] || hostname;

  return !!['localhost', '10', '127', '172', '192'].find((it) => it === host);
};

// in case of local development retrieve the realm from the environment
// in production setup, retrieve it from the current location (e.g. https://organizer-dummy.yroom.app => "dummy")
export const parseRealm = () => {
  let realmCandidate;
  let isDevSystem = true;

  if (isLocalHost()) {
    realmCandidate = process.env.REACT_APP_REALM || '';
  } else {
    const { hostname } = window.location;
    const hostParts = hostname.split('.');
    const host = hostParts?.[0] || hostname;
    realmCandidate = host.split(ORGANIZER_URL_PREFIX)?.[1] || host;

    isDevSystem = hostParts === 'dev';
  }
  // if the realm has the '-ov' suffix, we remove it
  const ovMatcher = new RegExp(`${OPENVIDU_URL_SUFFIX}$`);
  const isOVClient = ovMatcher.test(realmCandidate);
  let realm = realmCandidate;
  if (isOVClient) {
    realm = realmCandidate.replace(OPENVIDU_URL_SUFFIX, '');
  }

  return { realm, isOVClient, isDevSystem };
};

export const getRealm = () => {
  const { realm } = parseRealm();
  return realm;
};

// Gets the realm for Keycloak
// For dev systems, the realm is prefixed with 'develop-' (e.g. 'develop-isarlife')
export const getKeycloakRealm = () => {
  const { realm, isDevSystem } = parseRealm();

  return isDevSystem ? `develop-${realm}` : realm;
};

// Gets the URL of the state-server from the environment variables depending on the realm
// If the realm ends in '-ov' (e.g. https://organizer-dummy-ov.yroom.app) we choose the OpenVidu state-server
export const getStateServerUrl = () => {
  const stateServerUrlOT = process.env.REACT_APP_WS_URL || '';
  const stateServerUrlOV = process.env.REACT_APP_WS_URL_OV || '';

  const { isOVClient } = parseRealm();

  return isOVClient ? stateServerUrlOV : stateServerUrlOT;
};

export const getAttendeeUrl = () => {
  if (isLocalHost()) {
    return LOCAL_ATTENDEE_URL;
  }

  const { origin } = window.location;

  return origin.replace(ORGANIZER_URL_PREFIX, '');
};

export const getAdminUrl = () => {
  if (isLocalHost()) {
    return LOCAL_ADMIN_URL;
  }

  const { origin } = window.location;

  return origin.replace(ORGANIZER_URL_PREFIX, ADMIN_URL_PREFIX);
};
