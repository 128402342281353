import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPreferences, setVideoFitMode, fitModes } from './Settings';
import { ButtonRound } from '../atoms';

const ToggleVideoFit = () => {
  const { t } = useTranslation();
  const [preferences, setPreferences] = useState(getPreferences());

  const handleToggleClick = () => {
    const fitMode =
      preferences.video.fitMode === fitModes.contain
        ? fitModes.cover
        : fitModes.contain;
    const nextPreferences = { ...preferences, video: { fitMode } };
    setPreferences(nextPreferences);
    setVideoFitMode({ mode: fitMode, preferences: nextPreferences });
  };

  const getIcon = () => {
    return preferences.video.fitMode === fitModes.contain
      ? 'icon-contain'
      : 'icon-cover';
  };

  return (
    <div data-testid="toggle_video_fit" data-tip={t('videoFitMode')}>
      <ButtonRound size="big" onClick={handleToggleClick}>
        <i className={getIcon()} />
      </ButtonRound>
    </div>
  );
};

export default ToggleVideoFit;
