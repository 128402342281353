/* eslint-disable class-methods-use-this */
import React from 'react';
import OT from '@opentok/client';

const AUDIO_ACTIVE_TIME_MS_THRESHOLD = 300;
const AUDIO_INACTIVE_TIME_MS_THRESHOLD = 2000;

export class SessionOT {
  session;

  onSubscriberConnected;

  onSubscriberDisconnected;

  constructor(apiKey, sessionId) {
    this.session = OT.initSession(apiKey, sessionId);
  }

  initPublisher(publisherSelector, configuration = {}) {
    const config = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: { buttonDisplayMode: 'off', nameDisplayMode: 'on' },
      ...configuration,
    };
    return OT.initPublisher(publisherSelector, config, (error) => {
      if (error) {
        console.error(error);
      }
    });
  }

  checkScreenShareCapability() {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  initScreenShare(cssSelector, onPublisherCreated, onPublisherStopped) {
    let error;
    const publisher = this.initPublisher(
      'screenSharePublisher',
      {
        videoSource: 'screen',
        insertMode: 'append',
        width: '100%',
        height: '100%',
        style: {
          buttonDisplayMode: 'off',
        },
      },
      (e) => {
        error = e;
      }
    );
    if (error) {
      console.error(error);
    } else {
      publisher.on('mediaStopped', () => {
        onPublisherStopped(publisher);
      });
      this.getNativeSession().publish(publisher, (err) => {
        if (err) {
          console.error(err);
        } else {
          onPublisherCreated(publisher);
        }
      });
    }
  }

  getNativeSession() {
    return this.session;
  }

  getCycleVideoCallback(publisher) {
    return new Promise((resolve) => {
      OT.getDevices((error, devices) => {
        const hasManyVideoDevices =
          (devices || []).filter((element) => element.kind === 'videoInput')
            .length > 1;
        if (hasManyVideoDevices) {
          resolve(() => {
            publisher.cycleVideo();
          });
        } else {
          resolve(undefined);
        }
      });
    });
  }

  connect(token) {
    return new Promise((resolve, reject) => {
      this.session.connect(token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  disconnect() {
    if (this.session) {
      this.session.disconnect();
    }
  }

  // eslint-disable-next-line
  subscribe(stream, elementId, configuration = {}, onError) {
    let audioActivity;

    const config = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: { buttonDisplayMode: 'off', nameDisplayMode: 'on' },
      ...configuration,
    };

    const subscription = this.session.subscribe(
      stream,
      elementId,
      config,
      onError
    );

    subscription.on('videoElementCreated', (event) => {
      const { element } = event.target;

      if (element) {
        const audioMuted = document.createElement('i');
        audioMuted.classList.add('icon-mic-off');
        audioMuted.setAttribute('data-testid', 'mute_indicator');
        element.append(audioMuted);
      }

      if (!stream?.hasAudio) {
        /**
         * if the subscriber is joining the call w/o allowing mic access,
         * set the correct class to show the muted icon
         */
        element?.classList?.add('audio-indicator-off');
      }
    });

    subscription.on('destroyed', (event) => {
      if (this.onSubscriberDisconnected) {
        this.onSubscriberDisconnected(event);
      }
    });

    subscription.on('connected', (event) => {
      if (this.onSubscriberConnected) {
        this.onSubscriberConnected(event);
      }
    });

    subscription.on('audioLevelUpdated', (event) => {
      // audio on/ff indication
      if (!stream.hasAudio) {
        event.target.element.classList.add('audio-indicator-off');
      } else {
        event.target.element.classList.remove('audio-indicator-off');
      }

      // speech detection
      const now = Date.now();

      if (event.audioLevel > 0.2) {
        if (!audioActivity) {
          audioActivity = { timestamp: now, talking: false };
        } else if (audioActivity.talking) {
          audioActivity.timestamp = now;
        } else if (
          now - audioActivity.timestamp >
          AUDIO_ACTIVE_TIME_MS_THRESHOLD
        ) {
          audioActivity.talking = true;
          event.target.element.classList.add('video-subscriber-audio-active');
        }
      } else if (
        audioActivity &&
        now - audioActivity.timestamp > AUDIO_INACTIVE_TIME_MS_THRESHOLD
      ) {
        if (audioActivity.talking) {
          event.target.element.classList.remove(
            'video-subscriber-audio-active'
          );
        }
        audioActivity = null;
      }
    });

    return subscription;
  }

  setOnSubscriberConnected(callback) {
    this.onSubscriberConnected = callback;
  }

  setOnSubscriberDisconnected(callback) {
    this.onSubscriberDisconnected = callback;
  }

  renderVideoContainer() {
    return (
      <div
        data-testid="publisher"
        id="publisher"
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
}
