import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './PdfViewer.scss';
import MediaViewerToolbar from './MediaViewerToolbar';
import { Pointer } from '../../atoms';
import { ComChannelContext } from '../../services/ComChannel';
import { trackMediaSequence } from '../../utils';

const RENDER_STATE = {
  lastRequestedPage: 0,
};

const PdfViewer = ({
  media,
  isOrganizer,
  onClose,
  onStateChange,
  attendeeReady,
}) => {
  const channel = useContext(ComChannelContext);

  const [loading, setLoading] = useState(true);
  const viewerWrapper = useRef(null);
  const viewer = useRef(null);
  const canvas = useRef(null);

  const [pdfDoc, setPdfDoc] = useState(null);
  const renderingInProgress = useRef(false);
  const [, triggerRender] = useState(null);
  const currentPageNumber = useRef(0);

  const renderPage = useCallback((pageNumber, doc) => {
    const getScaleFactor = (page) => {
      const container = document.querySelector('#container');
      const widthViewer = container.clientWidth;
      const heightViewer = container.clientHeight;

      const { view } = page;
      const widthPage = Math.abs(view[2] - view[0]);
      const heightPage = Math.abs(view[3] - view[1]);

      const pageWidthScale = widthViewer / widthPage;
      const pageHeightScale = heightViewer / heightPage;

      return Math.min(pageWidthScale, pageHeightScale);
    };

    return doc.getPage(pageNumber).then((page) => {
      const scaleFactor = getScaleFactor(page);
      const viewport = page.getViewport(scaleFactor);

      const { width } = viewport;
      const { height } = viewport;
      canvas.current.width = width;
      canvas.current.height = height;
      viewer.current.style.height = `${height}px`;
      viewer.current.style.width = `${width}px`;

      const renderContext = {
        canvasContext: canvas.current.getContext('2d'),
        viewport,
      };
      const renderTask = page.render(renderContext);

      return renderTask.promise.then(() => pageNumber);
    });
  }, []);

  const handlePageTransition = useCallback(
    (toPageNumber, doc) => {
      const isValid = toPageNumber >= 1 && toPageNumber <= doc.numPages;

      if (!isValid) {
        return;
      }

      trackMediaSequence(toPageNumber + 1);

      if (renderingInProgress.current) {
        // Remember last requested page to be rendered while rendering is in progress
        RENDER_STATE.lastRequestedPage = toPageNumber;
      } else {
        renderingInProgress.current = true;
        currentPageNumber.current = toPageNumber;
        triggerRender({});
        renderPage(toPageNumber, doc).then((pageNumberRendered) => {
          renderingInProgress.current = false;
          onStateChange({
            data: JSON.stringify({
              namespace: 'pdfViewer',
              state: {
                page: pageNumberRendered,
              },
            }),
          });
          if (RENDER_STATE.lastRequestedPage) {
            handlePageTransition(RENDER_STATE.lastRequestedPage, doc);
            RENDER_STATE.lastRequestedPage = 0;
          }
        });
      }
    },
    [onStateChange, renderPage]
  );

  // Load PDF document when media was selected
  useEffect(() => {
    window.pdfjsLib
      .getDocument({
        url: media.contentDocument.downloadUrl,
        isEvalSupported: false
      })
      .then((doc) => {
        setPdfDoc(doc);
        setLoading(false);
        if (!isOrganizer) {
          channel.attendeeReady(media);
        }
        handlePageTransition(1, doc);
      });
  }, [channel, media, isOrganizer, handlePageTransition]);

  // Attendee side
  useEffect(() => {
    if (!pdfDoc) {
      return;
    }

    if (!isOrganizer) {
      // eslint-disable-next-line
      return channel.onPresentationStateChanged((event) => {
        handlePageTransition(event.state.page, pdfDoc);
      });
    }
  }, [pdfDoc, handlePageTransition, isOrganizer, channel]);

  // Organizer side
  useEffect(() => {
    if (!pdfDoc) {
      return;
    }

    const currentViewer = viewerWrapper.current;
    const config = {
      39: () => handlePageTransition(currentPageNumber.current + 1, pdfDoc),
      37: () => handlePageTransition(currentPageNumber.current - 1, pdfDoc),
      27: onClose,
    };

    // Page navigation via keyboard,
    let toolbarListener;
    if (isOrganizer) {
      toolbarListener = (e) => config[e.keyCode] && config[e.keyCode]();
      currentViewer.addEventListener('keydown', toolbarListener);
    }

    // eslint-disable-next-line
    return () => {
      currentViewer.removeEventListener('keydown', toolbarListener);
    };
  }, [pdfDoc, viewerWrapper, isOrganizer, handlePageTransition, onClose]);

  return (
    <div
      data-testid="pdf_viewer"
      className="pdf-v-wrapper focusable"
      // eslint-disable-next-line
      tabIndex="0"
      ref={viewerWrapper}
    >
      {loading && (
        <div className="loader-full-size">
          <div className="simple-loader simple-loader-small" />
        </div>
      )}
      <div id="container" style={{ width: '100%', height: '95%' }}>
        <div className="pdf-viewer" ref={viewer}>
          <Pointer isOrganizer={isOrganizer}>
            <canvas data-testid="pdf_viewer_canvas" ref={canvas} />
          </Pointer>
        </div>
      </div>
      {pdfDoc && isOrganizer && (
        <MediaViewerToolbar
          onClose={onClose}
          onNextPage={() =>
            handlePageTransition(currentPageNumber.current + 1, pdfDoc)
          }
          onPrevPage={() =>
            handlePageTransition(currentPageNumber.current - 1, pdfDoc)
          }
          middleSection={() =>
            `${currentPageNumber.current} / ${pdfDoc.numPages}`
          }
          attendeeReady={attendeeReady}
        />
      )}
    </div>
  );
};

export default PdfViewer;
