import { add } from 'date-fns';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-tabify';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { apiClient } from '../services/ApiClient';
import featureEnabled, { features } from '../services/FeatureFlag';
import SessionEventType from '../services/SessionEventType';
import { AsyncButton, Label } from '../atoms';

const RoomCreation = ({ attendees, onRoomCreation, onRoomCreationCancel }) => {
  const schedulingEnabled = featureEnabled(features.SCHEDULING);
  const attendeeNames =
    attendees && attendees.length > 0
      ? attendees.map((it) => it.displayName).join(', ')
      : '-';
  const { t } = useTranslation();

  const tabsTheme = {
    menu: {
      active: {
        backgroundColor: '#278cce',
      },
    },
  };

  const [selectedName, setSelectedName] = useState(attendeeNames);
  const [selectedStartDate, setSelectedStartDate] = useState(
    add(new Date(), { hours: 1 })
  );

  const createRoom = async (name, startDate) => {
    const start = startDate ? new Date(startDate) : new Date();
    const attendeeIds = attendees ? attendees.map((it) => it.id) : [];
    const room = await apiClient.init(
      attendeeIds,
      start.toISOString(),
      selectedName
    );
    const eventData = {
      attendees: attendeeIds,
    };
    await apiClient.addSessionEvent(
      room.id,
      SessionEventType.ROOM_CREATED,
      eventData
    );
    onRoomCreation(room);
  };

  const renderNameInput = () => {
    return (
      <>
        <div>
          <Label keyId="attendees" size="small" />
          <div>{attendeeNames}</div>
        </div>
        <div>
          <Label keyId="roomName" size="small" />
          <input
            id="roomName"
            type="text"
            value={selectedName}
            onChange={(event) => setSelectedName(event.target.value)}
          />
        </div>
      </>
    );
  };

  return (
    <Tabs theme={tabsTheme}>
      <Tab label={t('createRoom')}>
        {renderNameInput()}
        <br />
        <div className="row row-space-around">
          <AsyncButton
            id="create_room"
            onClick={() => createRoom(selectedName)}
          >
            {t('createRoom')}
          </AsyncButton>
          <AsyncButton onClick={onRoomCreationCancel} className="button-error">
            {t('cancel')}
          </AsyncButton>
        </div>
      </Tab>
      {schedulingEnabled && (
        <Tab label={t('planRoom')}>
          {renderNameInput()}
          <Label keyId="startDate" />
          <DatePicker
            selected={selectedStartDate}
            minDate={add(new Date(), { hours: 1 })}
            onChange={setSelectedStartDate}
            showTimeSelect
            inline
            dateFormat="Pp"
          />
          <br />
          <div className="row row-space-around">
            <AsyncButton
              onClick={() => createRoom(selectedName, selectedStartDate)}
            >
              {t('createRoom')}
            </AsyncButton>
            <AsyncButton
              onClick={onRoomCreationCancel}
              className="button-error"
            >
              {t('cancel')}
            </AsyncButton>
          </div>
        </Tab>
      )}
    </Tabs>
  );
};

export default RoomCreation;
