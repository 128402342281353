import React from 'react';
import './AttendeeInfo.scss';
import AttendeeOrganization from './AttendeeOrganization';
import { SimpleList } from '../atoms';

const AttendeeInfo = ({ attendee }) => {
  const data = attendee.attendeeData || {};
  const employments = data.organizations || [];
  const additionalInfo = data.blocks
    ? data.blocks.map((it) => {
        return { title: it.name, values: it.values };
      })
    : [];

  return (
    <div className="attendee-info">
      <header className="attendee-info-header" />

      <div className="iter-v-spacer-huge iter-h-spacer-huge row row-wrap priority overflow-y-auto font-smaller">
        {employments.map((it, index) => (
          // eslint-disable-next-line
          <AttendeeOrganization key={index} organization={it} />
        ))}

        {additionalInfo.map((it, index) => (
          // eslint-disable-next-line
          <SimpleList key={index} title={it.title} items={it.values} />
        ))}
      </div>
    </div>
  );
};

export default AttendeeInfo;
