import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '../atoms';
import { apiClient } from '../services/ApiClient';

const options = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
];

export const fitModes = {
  cover: 'cover',
  contain: 'contain',
};

const defaultPreferences = {
  video: {
    fitMode: fitModes.cover,
  },
};

export const setVideoFitMode = ({ mode, preferences }) => {
  const newPreferences = { ...preferences };
  newPreferences.video.fitMode = mode;
  apiClient.saveUserPreferencesToLocalStorage(newPreferences);
  document
    .querySelectorAll('.OT_fit-mode-contain, .OT_fit-mode-cover')
    .forEach((el) => {
      el.classList.remove('OT_fit-mode-contain');
      el.classList.remove('OT_fit-mode-cover');
      el.classList.add(`OT_fit-mode-${mode}`);
    });
};

export const getPreferences = () => {
  return {
    ...defaultPreferences,
    ...apiClient.getUserPreferencesFromLocalStorage(),
  };
};

const Settings = ({ onClose }) => {
  const { i18n, t } = useTranslation();
  const [preferences, setPreferences] = useState(getPreferences());

  const fitModeOptions = [
    { value: fitModes.cover, label: t('videoFitModeCover') },
    { value: fitModes.contain, label: t('videoFitModeContain') },
  ];

  const getSelectedLocale = () => {
    return i18n.language.split('-')[0];
  };

  const getFitMode = () => {
    return preferences.video.fitMode;
  };

  const handleFitModeChange = (mode) => {
    const newPreferences = { ...preferences, ...{ video: { fitMode: mode } } };
    setPreferences(newPreferences);
    setVideoFitMode({ mode, preferences: newPreferences });
  };
  const handleLocaleChange = (event) => {
    i18n.changeLanguage(event);
    if (onClose) {
      onClose();
    }
  };

  return (
    <section className="column h100">
      <b>
        <Label keyId="settings" />
      </b>
      <div className="row v-spacer-big">
        <div className="flex-1">
          <Label keyId="language" />
        </div>
        <div className="flex-1">
          <Select
            options={options}
            selectedValue={getSelectedLocale}
            onChange={handleLocaleChange}
          />
        </div>
      </div>
      <div className="row row-space-between v-spacer-big">
        <div className="flex-1">
          <Label keyId="videoFitMode" />
        </div>
        <div className="flex-1">
          <Select
            options={fitModeOptions}
            selectedValue={getFitMode()}
            onChange={handleFitModeChange}
          />
        </div>
      </div>
    </section>
  );
};

export default Settings;
