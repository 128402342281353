import React, { useCallback, useEffect, useState } from 'react';
import './AppOrganizerRoom.scss';
import { Tab, Tabs } from 'react-tabify';
import { differenceInMilliseconds } from 'date-fns';
import Video from '../components/Video';
import ProcessLauncher from './ProcessLauncher';
import SecondaryActions from './SecondaryActions';
import MediaViewer from '../components/MediaViewer/MediaViewer';
import ShareScreen from '../components/ShareScreen';
import Store from '../services/Store';
import AttendeeInfo from '../components/AttendeeInfo';
import { apiClient } from '../services/ApiClient';
import * as rtcClient from '../services/rtcClient';
import useRemoveLoader from '../components/useRemoveLoader';
import { ComChannelContext, initChannel } from '../services/ComChannel';
import ShareScreenSubscriber from '../components/ShareScreenSubscriber';
import featureEnabled, { features } from '../services/FeatureFlag';

const TAB_HEADER_HEIGHT = '50px';

const AppOrganizerRoom = ({ ...props }) => {
  const [roomId, setRoomId] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [channel, setChannel] = useState(null);
  const [media, setMedia] = useState(null);
  const [screenShareAvailable, setScreenShareAvailable] = useState(false);
  const [screenSharePublisher, setScreenSharePublisher] = useState(null);
  const [closeStage, setCloseStage] = useState(false);
  const [videoSession, setVideoSession] = useState(null);
  const [activeKey, setActiveKey] = useState(0);
  const [currentSubscriberCount, setCurrentSubscriberCount] = useState(0);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [showAttendeeInfo, setShowAttendeeInfo] = useState(true);
  const [screenShareActive, setScreenShareActive] = useState(null);
  const [roomTypeScreenShareEnabled, setRoomTypeScreenShareEnabled] =
    useState(null);

  const [attendeeReady, setAttendeeReady] = useState({});

  useRemoveLoader();

  const saveMediaTracking = useCallback(() => {
    // If the feature flag is enable and there is entry for mediaTracking inside the Store we sent the media tracking data to the api
    if (featureEnabled(features.MEDIA_TIME_TRACKING) && Store.mediaTracking) {
      // Create data to send to the server
      const mediaTracking = {
        ...Store.mediaTracking,
        trackingInfo: Store.mediaTracking.trackingInfo.map((info, index) => ({
          sequence: info.sequence,
          page: info.page,
          // Transform the startTime of each sequence into duration time
          time: differenceInMilliseconds(
            // Each sequence will use the startTime of the next sequence to calculate the duration of itself. The last sequence will calculate its duration by the current time
            index + 1 < Store.mediaTracking.trackingInfo.length
              ? Store.mediaTracking.trackingInfo[index + 1].startTime
              : new Date(),
            info.startTime
          ),
        })),
      };
      // Send the final mediaTracking data that api requests
      apiClient.trackMedia(mediaTracking).then(() => {
        // Clear the mediaTracking data existing in the system after the request completes.
        Store.mediaTracking = null;
      });
    }
  }, []);

  useEffect(() => {
    const match = props.location.pathname.match(
      /([ABCDEFabcdef0123456789-]{36})/g
    );
    if (match) {
      setRoomId(match[0]);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (roomId) {
      apiClient.getRoom(roomId).then((room) => {
        setSelectedRoom(room);
        Store.roomData = room;
      });
    }
  }, [roomId]);

  useEffect(() => {
    if (selectedRoom) {
      const attendees = selectedRoom.attendees
        ? selectedRoom.attendees.map((it) => it.id)
        : [];
      const roomScreenShareEnabled =
        selectedRoom.roomType && selectedRoom.roomType.screenSharingEnabled;

      setRoomTypeScreenShareEnabled(roomScreenShareEnabled);

      if (!channel) {
        initChannel(
          'organizer',
          attendees,
          selectedRoom.id,
          selectedRoom.code,
          setCurrentSubscriberCount
        ).then((newChannel) => {
          setChannel(newChannel);
          const roomInfo = newChannel.getRoomInfo();

          rtcClient
            .initSession({
              apiKey: roomInfo.apiKey,
              token: roomInfo.token,
              sessionId: roomInfo.sessionId,
              rtcProvider: roomInfo.rtcProvider,
            })
            .then((session) => {
              session
                .checkScreenShareCapability()
                .then((screenShareSupported) => {
                  const featureAvailable =
                    roomScreenShareEnabled && screenShareSupported;
                  setVideoSession(session);
                  setScreenShareAvailable(featureAvailable);
                });
            });

          setAttendeeReady({
            ready: 0,
            total: newChannel.attendees.length,
          });
        });
      }

      if (attendees) {
        setSelectedAttendee(selectedRoom.attendees[0]);
      }
    }
    return () => {
      if (channel) {
        channel.close();
      }
    };
  }, [selectedRoom, channel]);

  useEffect(() => {
    return () => {
      if (videoSession) {
        console.log('Video session destroy.');
        videoSession.disconnect();
      }
    };
  }, [videoSession]);

  useEffect(() => {
    let unsubscribe;
    if (channel) {
      unsubscribe = channel.onAttendeeReadyOrganizerNotify(() => {
        const info = attendeeReady;
        if (Object.keys(info).length) {
          info.ready = ++info.ready;
          info.total = currentSubscriberCount;
          setAttendeeReady(info);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [channel, currentSubscriberCount, attendeeReady]);

  const handleScreenShare = () => {
    if (screenSharePublisher || screenShareActive) {
      return;
    }

    videoSession.initScreenShare(
      screenSharePublisher,
      (publisher) => {
        setScreenSharePublisher(publisher);
        closeAllButVideo();
      },
      (publisher) => {
        handleScreenShareUnPublish(publisher);
      }
    );
  };

  const handleScreenShareUnPublish = (publisher) => {
    if (publisher) {
      videoSession.getNativeSession().unpublish(publisher);
      setScreenSharePublisher(null);
    }
  };

  const getShareScreenHandler = () => {
    return screenShareAvailable ? handleScreenShare : null;
  };

  const handleOnScreenShareActive = (active) => {
    setScreenShareActive(active);
    if (active) {
      closeAllButVideo();
    }
  };

  const handleSetMedia = (it) => {
    closeAllButVideo();
    setMedia(it);
  };

  const closeAllButVideo = () => {
    setMedia(null);
    setCloseStage(false);
    setAttendeeReady({
      ready: 0,
      total: currentSubscriberCount,
    });
    handleScreenShareUnPublish(screenSharePublisher);
  };

  const onMediaViewerClose = () => {
    saveMediaTracking();
    closeAllButVideo();
  };

  const cleanStage = () => {
    setCloseStage(!closeStage);
    if (showAttendeeInfo) {
      toggleAttendeeInfoPanel();
    }
  };

  const changeSelectedAttendee = (newActiveKey) => {
    setActiveKey(newActiveKey);
    setShowAttendeeInfo(newActiveKey === activeKey ? !showAttendeeInfo : true);
    setSelectedAttendee(selectedRoom.attendees[newActiveKey]);
  };

  const isSecondaryActionsClosed = () => media;
  const hasAttendees =
    selectedRoom && selectedRoom.attendees && selectedRoom.attendees.length > 0;
  const isVideoInFocus = () => !(media || screenShareActive);

  const toggleAttendeeInfoPanel = () => {
    setShowAttendeeInfo(!showAttendeeInfo);
    if (!selectedAttendee) {
      setSelectedAttendee(selectedRoom.attendees[0]);
    }
  };

  const getOwnerName = () => {
    if (selectedRoom && selectedRoom.ownerName) {
      return selectedRoom.ownerName;
    }
    return 'organizer';
  };

  const getMainPanelStyle = () => {
    if (hasAttendees) {
      return showAttendeeInfo
        ? { height: '66.66vh' }
        : { height: `calc(100vh - ${TAB_HEADER_HEIGHT})` };
    }

    return null;
  };

  const getFooterPanelStyle = () =>
    showAttendeeInfo ? { height: '33.33vh' } : { height: TAB_HEADER_HEIGHT };

  return (
    <div className="app-o">
      {selectedRoom && channel && (
        <ComChannelContext.Provider value={channel}>
          <div className="column h100 w100">
            <div className="app-o-main-content" style={getMainPanelStyle()}>
              <section className="mainSection">
                {videoSession && (
                  <Video
                    session={videoSession}
                    streamName={getOwnerName()}
                    screenShareEnabled={roomTypeScreenShareEnabled}
                    focused={isVideoInFocus()}
                    isOrganizer={true}
                  />
                )}

                {videoSession && (
                  <ShareScreenSubscriber
                    session={videoSession}
                    onPublishStreamActive={handleOnScreenShareActive}
                  />
                )}

                <ShareScreen
                  shareScreenPublished={!!screenSharePublisher}
                  onClose={() =>
                    handleScreenShareUnPublish(screenSharePublisher)
                  }
                />

                {media && (
                  <MediaViewer
                    media={media}
                    isOrganizer
                    onClose={onMediaViewerClose}
                    attendeeReady={attendeeReady}
                  />
                )}
              </section>

              <ProcessLauncher
                room={selectedRoom}
                channel={channel}
                currentSubscriberCount={currentSubscriberCount}
                onMediaSelected={handleSetMedia}
                onShareScreen={getShareScreenHandler()}
                closeStage={closeStage}
                saveMediaTracking={saveMediaTracking}
                onClose={closeAllButVideo}
              />
            </div>
            {hasAttendees && (
              <div className="app-o-main-footer" style={getFooterPanelStyle()}>
                <Tabs activeKey={activeKey} onSelect={changeSelectedAttendee}>
                  {selectedRoom.attendees.map((attendee, index) => {
                    return (
                      // eslint-disable-next-line
                      <Tab key={index} label={attendee.displayName}>
                        {showAttendeeInfo && (
                          <div className="padding-big">
                            <AttendeeInfo attendee={attendee} />
                          </div>
                        )}
                      </Tab>
                    );
                  })}
                </Tabs>
                <div className="attendee-info-panel-close menu-icon">
                  {/* eslint-disable-next-line */}
                  <i
                    data-testid="attendee_info_panel_close"
                    className={`${
                      showAttendeeInfo ? 'icon-arrow-down' : 'icon-arrow-up'
                    } with-i-hover f-small`}
                    onClick={toggleAttendeeInfoPanel}
                  />
                </div>
              </div>
            )}
          </div>

          <SecondaryActions
            roomData={selectedRoom}
            isClosed={isSecondaryActionsClosed()}
            cleanParentStage={cleanStage}
          />
        </ComChannelContext.Provider>
      )}
    </div>
  );
};

export default AppOrganizerRoom;
