import React, { useState, useEffect, useRef } from 'react';
import { isNumber } from 'lodash';
import './NumberInput.scss';

const defaultValue = (value) => (isNumber(value) ? value : 0);

export const NumberInput = ({
  min = 0,
  max,
  plusDisabled,
  minusDisabled,
  onChange,
}) => {
  const onChangeRef = useRef(onChange);
  const [value, setValue] = useState(defaultValue(min));

  const disableMinus = value - 1 < min || minusDisabled;
  const disablePlus = (isNumber(max) && value + 1 > max) || plusDisabled;

  useEffect(() => {
    onChangeRef.current(value);
  }, [value]);

  const handleOnSubtract = () => {
    if (disableMinus || minusDisabled) {
      return;
    }
    const next = value - 1;
    setValue(next);
    onChange(next);
  };

  const handleOnAdd = () => {
    if (disablePlus || plusDisabled) {
      return;
    }
    const next = value + 1;
    setValue(next);
    onChange(next);
  };

  return (
    <div className="number-input">
      {/* eslint-disable-next-line */}
      <div className="padding-big" onClick={handleOnSubtract}>
        <i
          className={`icon-minus color-accent ${
            disableMinus && 'number-input-input-disabled'
          }`}
        />
      </div>
      <div className="number-input-content">
        <input className="number-input-input" value={value} readOnly />
      </div>
      {/* eslint-disable-next-line */}
      <div className="padding-big" onClick={handleOnAdd}>
        <i
          className={`icon-plus color-accent  ${
            disablePlus && 'number-input-input-disabled'
          }`}
        />
      </div>
    </div>
  );
};
