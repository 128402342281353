import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, ListItem } from '../atoms';
import styles from './CloseButton.module.scss';

const SearchableList = forwardRef(
  (
    { items, label, onItemSelected, menuItems, className, onCloseItem },
    ref
  ) => {
    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const { t } = useTranslation();

    const handleSearch = (e) => {
      const searchTerm = e.target.value;
      setSearch(searchTerm);
      setFilteredItems(() => {
        return items.filter((it) => {
          const searchIn = `${it.name || ''}  ${
            it.description || ''
          }`.toLowerCase();
          return searchIn.indexOf(searchTerm.toLowerCase()) > -1;
        });
      });
    };

    const handleItemSelected = (item) => {
      onItemSelected(item);
    };

    const isItemSelected = (item) => {
      return item.$selected;
    };

    return (
      <section
        ref={ref}
        data-testid="list"
        className={`${className} padding-bigger column-stretch h100 ${styles.container}`}
      >
        {label && (
          <div data-testid="list_title">
            <b>
              <Label keyId={label} />
            </b>
          </div>
        )}

        <button
          type="button"
          className={styles.closeButton}
          onClick={onCloseItem}
        >
          &#10006;
        </button>

        <div className="v-spacer">
          <form action="">
            <input
              data-testid="list_search_box"
              type="text"
              value={search}
              placeholder={t('search')}
              onChange={handleSearch}
            />
          </form>
        </div>

        <div
          data-testid="list_items"
          className="overflow-y-auto fix-margin-shadow vw-height-30"
          style={{ position: 'relative' }}
        >
          {filteredItems.map((it) => {
            return (
              <ListItem
                key={it.id}
                id={it.id}
                title={it.name}
                selected={isItemSelected(it)}
                description={it.description}
                thumbnailUrl={
                  (it.thumbnailDocument && it.thumbnailDocument.downloadUrl) ||
                  ''
                }
                menuItems={menuItems}
                value={it}
                onClick={() => handleItemSelected(it)}
              />
            );
          })}
        </div>
      </section>
    );
  }
);

export default SearchableList;
