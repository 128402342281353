import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ShareScreenSubscriber.scss';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const ShareScreenSubscriber = ({ session, onPublishStreamActive }) => {
  const { t } = useTranslation();
  // const _onPublishStreamActive = useCallback(onPublishStreamActive,[]);
  const [screenShareActive, setScreenShareActive] = useState(false);
  const nativeSession = session.getNativeSession();

  useEffect(() => {
    const setSubscriberIsActive = (active) => {
      setScreenShareActive(active);
      // _onPublishStreamActive(active);
      onPublishStreamActive(active);
    };

    nativeSession.on('streamCreated', (event) => {
      const shouldSubscribe = event.stream.videoType === 'screen';
      if (shouldSubscribe) {
        const subscription = nativeSession.subscribe(
          event.stream,
          'screenShareSubscriber',
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            style: {
              buttonDisplayMode: 'off',
            },
          },
          (error) => {
            if (error) {
              toast(t('videoSubscriptionGeneralError'), {
                type: toast.TYPE.ERROR,
              });
            } else {
              setSubscriberIsActive(true);
            }
          }
        );
        subscription.on('destroyed', () => {
          setSubscriberIsActive(false);
        });
      }
    });
  }, [
    // _onPublishStreamActive,
    onPublishStreamActive,
    nativeSession,
    t,
  ]);

  const getCss = () => {
    return `screen-share-subscriber ${
      screenShareActive ? 'screen-share-subscriber-active' : ''
    }`;
  };

  const getVideoCss = () => {
    return `screen-share-subscriber-video ${
      screenShareActive ? 'screen-share-subscriber-active' : ''
    }`;
  };

  return (
    <div className={getCss()}>
      <div id="screenShareSubscriber" className={getVideoCss()} />
      <ReactTooltip />
    </div>
  );
};

export default ShareScreenSubscriber;
