import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import i18n from 'i18next';
import md5 from 'js-md5';
import { toast } from 'react-toastify';

import * as mutations from './Mutation';
import {
  addSessionEvent,
  attendeeDataList,
  attendeeDataQuery,
  cancelRoomMutation,
  completeRoomMutation,
  initRoomMutation,
  personalizeFormQuery,
  roomQuery,
  roomsQuery,
  samples,
  sendLinkMutation,
  storeFormInstanceMutation,
  transferFormInstance,
  transferRoomSession,
  updateRoomAttendeeMutation,
} from './Queries';
import Store from './Store';
import uuid from './UUID';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const USER_SETTINGS = 'userSettings';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${Store.token}`,
      'Accept-Language': i18n.language.split('-')[0],
      'X-Ysura-Realm': Store.realm,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (error.extensions && error.extensions.userMessage) {
        toast.error(error.extensions.userMessage, { autoClose: false });
      } else {
        toast.error(i18n.t('generalError'), { autoClose: false });
      }
    });
  }
  if (networkError) {
    toast.error(`${i18n.t('generalNetworkError')} ${networkError}`, {
      autoClose: false,
    });
  }
});

const apiClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

apiClient.init = (attendees, isoStartTime, name) => {
  apiClient.roomSetupKey = md5(window.location.href);
  const mutation = initRoomMutation(isoStartTime, name);
  return apiClient
    .mutate({
      variables: {
        attendees,
      },
      mutation,
    })
    .then((result) => {
      return apiClient.getRoom(result.data.initRoom.id).then((data) => {
        Store.roomSetup = data;
        localStorage.setItem(
          apiClient.roomSetupKey,
          JSON.stringify(Store.roomSetup)
        );
        return Store.roomSetup;
      });
    });
};

apiClient.logOut = () => {
  localStorage.removeItem(apiClient.roomSetupKey);
};

apiClient.logOutFromKeycloak = () => {
  apiClient.logOut();
  window.location.replace(Store.logoutUrl);
};

apiClient.saveToLocalStorage = (propertyName, value) => {
  const data = JSON.parse(localStorage.getItem(apiClient.roomSetupKey) || '{}');
  data[propertyName] = value;
  localStorage.setItem(apiClient.roomSetupKey, JSON.stringify(data));
};

apiClient.readFromLocalStorage = (propertyName) => {
  const data = JSON.parse(localStorage.getItem(apiClient.roomSetupKey) || '{}');
  return data[propertyName];
};

apiClient.saveUserPreferencesToLocalStorage = (object = {}) => {
  localStorage.setItem(USER_SETTINGS, JSON.stringify(object));
};

apiClient.getUserPreferencesFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem(USER_SETTINGS) || '{}');
};

apiClient.getRoom = (roomId) => {
  const query = roomQuery(roomId);
  return apiClient
    .query({
      query,
    })
    .then((result) => {
      Store.room = result.data.room;
      return Store.room;
    });
};

apiClient.getRooms = (attendees, states) => {
  return apiClient
    .query({
      variables: {
        attendees,
        states,
      },
      query: roomsQuery(),
    })
    .then((result) => {
      return result.data.rooms.data;
    });
};

apiClient.getAttendeeData = (attendeeIds) => {
  const query = attendeeDataQuery();
  return apiClient
    .query({
      variables: {
        attendeeIds,
      },
      query,
    })
    .then((result) => (result.data && result.data.attendeeData) || []);
};

apiClient.updateRoomAttendee = (
  id,
  attendeeId,
  displayName,
  email,
  isColleague
) => {
  const query = updateRoomAttendeeMutation();
  return apiClient.query({
    variables: {
      attendee: {
        id,
        attendeeId,
        displayName,
        email,
        isColleague,
      },
    },
    query,
  });
};

apiClient.getAttendeeList = (search, offset, isColleague) => {
  const query = attendeeDataList();
  return apiClient
    .query({
      variables: {
        search,
        offset,
        isColleague: !!isColleague,
      },
      query,
    })
    .then((result) => (result.data && result.data.attendeeList) || []);
};

apiClient.completeRoomMutation = (roomId, sessionMedia, sessionNotes) => {
  const mutation = completeRoomMutation(roomId, sessionMedia, sessionNotes);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.addFeedback = (roomId, rating, text) => {
  const mutation = mutations.addFeedback(uuid(), roomId, rating, text);
  return apiClient.mutate({ mutation });
};

apiClient.sendLinkMutation = (roomId, attendeeId, alternateEmail) => {
  const mutation = sendLinkMutation(roomId, attendeeId, alternateEmail);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.cancelRoomMutation = (roomId) => {
  const mutation = cancelRoomMutation(roomId);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.transferRoomSession = (roomId) => {
  const mutation = transferRoomSession(roomId);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.personalizeForm = (roomId, formId, attendeeId) => {
  const query = personalizeFormQuery(roomId, formId, attendeeId);
  return apiClient.query({
    query,
  });
};

apiClient.storeFormInstance = (roomId, formId, attendeeId, data, html) => {
  const formInstance = {
    id: uuid(),
    roomId,
    formId,
    attendeeId,
    data: data.map((it) => {
      return {
        name: it.name,
        value: JSON.stringify(it.value),
      };
    }),
    html,
  };
  const mutation = storeFormInstanceMutation();
  return apiClient.mutate({
    variables: {
      formInstance,
    },
    mutation,
  });
};

apiClient.submitSampleDrop = (sampleId, data, html) => {
  const $sampleDrop = {
    id: sampleId,
    data: data.map((it) => {
      return {
        name: it.name,
        value: JSON.stringify(it.value),
      };
    }),
    html,
  };
  const mutation = mutations.submitSampleDrop();
  return apiClient.mutate({
    variables: {
      sampleDrop: $sampleDrop,
    },
    mutation,
  });
};

apiClient.transferSampleDrop = (sampleDropId) => {
  const mutation = mutations.transferSampleDrop(sampleDropId);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.transferFormInstance = (formInstanceId) => {
  const mutation = transferFormInstance(formInstanceId);
  return apiClient.mutate({
    mutation,
  });
};

apiClient.addSessionEvent = (roomId, type, eventData) => {
  const sessionEvent = {
    roomId,
    type,
    data: eventData,
  };
  const mutation = addSessionEvent();
  return apiClient.mutate({
    variables: {
      sessionEvent,
    },
    mutation,
  });
};

apiClient.samples = (roomId, attendeeId) => {
  return apiClient
    .query({
      variables: {
        roomId,
        attendeeId,
      },
      query: samples(),
    })
    .then((result) => result.data && result.data.samples);
};

apiClient.prepareSampleDrop = (configuration) => {
  return apiClient.mutate({
    variables: { configuration },
    mutation: mutations.prepareSampleDrop(),
  });
};

apiClient.trackMedia = (mediaTracking) => {
  return apiClient.mutate({
    variables: { mediaTracking },
    mutation: mutations.trackMedia(),
  });
};

export { apiClient };
