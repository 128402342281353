import React, { useContext, useEffect, useRef, useState } from 'react';
import './HtmlViewer.scss';
import MediaViewerToolbar from './MediaViewerToolbar';
import { Pointer } from '../../atoms';
import { ComChannelContext } from '../../services/ComChannel';
import { trackMediaSequence } from '../../utils';

const HtmlViewer = ({
  media,
  isOrganizer,
  onClose,
  onStateChange,
  attendeeReady,
}) => {
  const channel = useContext(ComChannelContext);
  const [loading, setLoading] = useState(true);
  const frame = useRef(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  useEffect(() => {
    let unsubscribe;
    if (!isOrganizer) {
      unsubscribe = channel.onPresentationStateChanged((event) => {
        if (frame.current) {
          frame.current.contentWindow.postMessage(event, '*');
        }
      });
    }
    frame.current.onload = () => {
      setLoading(false);
      if (!isOrganizer) {
        channel.attendeeReady(media);
      }
    };

    const receiveMessage = (event) => {
      const data =
        event.data &&
        typeof event.data === 'string' &&
        event.data.indexOf('reveal') > 0;
      if (data && event.data.eventName === 'slidechanged') {
        const revealEvent = JSON.parse(event.data);

        setCurrentPageNumber(revealEvent.state.indexh);
        trackMediaSequence(revealEvent.state.indexh + 1);
      }
    };

    if (isOrganizer) {
      window.addEventListener('message', receiveMessage, false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (isOrganizer) {
        window.removeEventListener('message', receiveMessage);
      }
    };
  }, [channel, isOrganizer, media]);

  const handleOnContentClick = (e) => {
    if (!isOrganizer) {
      e.stopPropagation();
    }
  };

  const handlePageTransition = (offset) => {
    const nextPage = currentPageNumber + offset;

    if (nextPage >= 0 && nextPage !== currentPageNumber) {
      setCurrentPageNumber(nextPage);
      trackMediaSequence(nextPage + 1);

      const event = {
        eventName: 'slidechanged',
        namespace: 'reveal',
        state: {
          indexh: nextPage,
          indexv: 0,
          paused: false,
          overview: false,
        },
      };
      frame.current.contentWindow.postMessage(event, '*');
      onStateChange(event);
    } else {
      setCurrentPageNumber(0);
    }
  };

  const getContentCss = () =>
    `priority html-viewer-content h100 ${loading && 'visibility-hidden'}`;

  return (
    <div data-testid="html_viewer" className="html-viewer">
      {loading && (
        <div className="loader-full-size">
          <div className="simple-loader simple-loader-small" />
        </div>
      )}

      <div className={getContentCss()}>
        {!isOrganizer && (
          // eslint-disable-next-line
          <div
            className="html-viewer-content-blocker"
            onClick={handleOnContentClick}
          />
        )}
        <Pointer isOrganizer={isOrganizer} iFrameRef={frame}>
          <iframe
            data-testid="html_viewer_frame"
            src={media.contentDocument.downloadUrl}
            ref={frame}
            title="Viewer"
            style={{ width: '100%', height: '100%' }}
          />
        </Pointer>
      </div>

      {isOrganizer && (
        <MediaViewerToolbar
          onClose={onClose}
          onNextPage={() => handlePageTransition(+1)}
          onPrevPage={() => handlePageTransition(-1)}
          attendeeReady={attendeeReady}
        />
      )}
    </div>
  );
};

export default HtmlViewer;
