import React, { forwardRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import logoVertical from '../images/logo-vertical.png';
import './SideBarMenu.scss';

const SideBarMenu = forwardRef(
  (
    {
      currentSubscriberCount,
      onShowMediaLibrary,
      onShowFormLibrary,
      onScreenShare,
      onSampleDrop,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const handleOnScreenShareClick = () => {
      onScreenShare();
    };

    return (
      <div ref={ref} className="side-bar-menu card column-center">
        <div className="side-bar-menu-top column-center priority">
          <div data-testid="menu_logo" className="logo-vertical">
            <img src={logoVertical} alt="" />
          </div>
          {/* eslint-disable-next-line */}
          <div
            data-testid="menu_media"
            className="menu-icon"
            data-tip={t('mediaLibrary')}
            data-place="right"
            onClick={onShowMediaLibrary}
          >
            <i className="icon-media" />
          </div>
          {currentSubscriberCount < 2 && (
            // eslint-disable-next-line
            <div
              data-testid="menu_forms"
              className="menu-icon"
              data-tip={t('formLibrary')}
              data-place="right"
              onClick={onShowFormLibrary}
            >
              <i className="icon-consent" />
            </div>
          )}
          {currentSubscriberCount < 2 && onSampleDrop && (
            // eslint-disable-next-line
            <div
              data-testid="menu_sample_drop"
              className="menu-icon"
              data-tip={t('sampleDrop')}
              data-place="right"
              onClick={onSampleDrop}
            >
              <i className="icon-pill" />
            </div>
          )}
          {onScreenShare && (
            // eslint-disable-next-line
            <div
              data-testid="menu_forms"
              className="menu-icon"
              data-tip={t('shareScreen')}
              data-place="right"
              onClick={handleOnScreenShareClick}
            >
              <i className="icon-cast-connected" />
            </div>
          )}
        </div>
        <ReactTooltip />
      </div>
    );
  }
);

export default SideBarMenu;
