import axios from 'axios';
import { getRealm } from '../utils';
import uuid from './UUID';

const apiUrl = process.env.REACT_APP_API_URL;
const realm = getRealm();

if (realm) {
  axios.defaults.headers.common = {
    'X-Ysura-Realm': realm,
  };
}

const apiRest = {};

apiRest.getRoom = (codeOrId) => {
  return axios
    .get(`${apiUrl}/room/${codeOrId}`)
    .then((result) => {
      return result.data;
    })
    .catch(() => {
      return null;
    });
};

apiRest.addFeedback = ({ roomId, rating, text }) => {
  return axios
    .post(`${apiUrl}/feedback`, {
      id: uuid(),
      roomId,
      rating,
      text,
    })
    .then((result) => {
      return result.data;
    })
    .catch(() => {
      return null;
    });
};

export default apiRest;
