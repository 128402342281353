import useAttendeeAuthentication from '../components/useAttendeeAuthentication';

const AttendeeAuthenticate = ({ roomId, history, children }) => {
  const room = useAttendeeAuthentication(roomId, history);
  if (room) {
    return children;
  }

  return null;
};

export default AttendeeAuthenticate;
