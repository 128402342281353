import { useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';
import i18next from 'i18next';
import Store from '../services/Store';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const useAuthentication = (realm, idpHint) => {
  const [authenticated, setAuthenticated] = useState(false);
  Store.realm = realm;

  useEffect(() => {
    const keycloak = new Keycloak({
      realm,
      url: AUTH_URL,
      clientId: 'yr-room-ui',
    });

    if (realm) {
      // Enable auto refresh of access token
      keycloak.onTokenExpired = () =>
        keycloak
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              console.log('[KEYCLOAK.onTokenExpired] Token refreshed.');
              Store.token = keycloak.token;
            } else {
              console.log('[KEYCLOAK.onTokenExpired] Token is still valid.');
            }
          })
          .catch(() => {
            console.log(
              '[KEYCLOAK.onTokenExpired] Failed to refresh the token, or the session has expired.'
            );
          });

      // Init Keycloak adapter
      keycloak
        .init({})
        .then((userAuthenticated) => {
          if (!userAuthenticated) {
            // If the user is not authenticated, redirect to the login page
            keycloak.login({
              locale: i18next.language,
              kcLocale: i18next.language,
              idpHint,
            });
          }
          const logoutUrl = keycloak.createLogoutUrl({
            redirectUri: window.location.origin,
          });
          setAuthenticated(userAuthenticated);
          Store.token = keycloak.token;
          Store.keycloak = keycloak;
          Store.tokenParsed = keycloak.tokenParsed;
          Store.logoutUrl = logoutUrl;
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setAuthenticated(false);
    }
  }, [realm, idpHint]);

  return [authenticated];
};

export default useAuthentication;
