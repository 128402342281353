import React, { useState } from 'react';
import { ButtonRound } from '../ButtonRound/ButtonRound';

const OnOffButton = ({ onClick, cssOn, cssOff, defaultOn, tip, size }) => {
  const [active, setActive] = useState(defaultOn);

  const handleToggle = () => {
    setActive((prev) => {
      const result = !prev;
      onClick(result);
      return result;
    });
  };

  return (
    <ButtonRound size={size} onClick={handleToggle}>
      <i
        data-testid={active ? cssOn : cssOff}
        className={active ? cssOn : cssOff}
        data-tip={tip}
      />
    </ButtonRound>
  );
};

export { OnOffButton };
