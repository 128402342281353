import useAuthentication from '../components/useAuthentication';
import { getKeycloakRealm } from '../utils';

const extractIdpHint = (queryStr) => {
  const regEx = /idpHint=([a-zA-Z]+)/;
  const matches = regEx.exec(queryStr);
  return matches && matches.length > 0 ? matches[1] : undefined;
};

const Authenticate = ({ location, children }) => {
  const { search } = location;
  const idpHint = extractIdpHint(search);

  const realm = getKeycloakRealm();

  const [authenticated] = useAuthentication(realm, idpHint);

  if (authenticated) {
    return children;
  }

  return null;
};

export default Authenticate;
