import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

const Alert = ({ onClose, ...props }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    if (onClose) {
      setOpen(false);
      onClose();
    }
  };

  return (
    <Modal
      styles={styles}
      open={open}
      showCloseIcon={!!onClose}
      center
      onClose={handleClose}
      focusTrapped={false}
    >
      {props.children}
    </Modal>
  );
};

const styles = {
  closeButton: {
    top: '-2px',
    right: '0',
    width: '1.2rem',
    height: '1rem',
    cursor: 'pointer',
  },
  modal: {
    borderRadius: '3px',
    border: 0,
  },
  overlay: {
    background: 'rgba(0,0,0,.28)',
  },
};

export { Alert };
