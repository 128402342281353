import React from 'react';
import { useTranslation } from 'react-i18next';

const Label = ({ keyId, size }) => {
  const className = size === 'small' ? 'font-small' : '';
  const { t } = useTranslation();
  return <span className={className}> {t(keyId)} </span>;
};

export { Label };
