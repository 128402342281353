import React, { useEffect, useState } from 'react';
import './AppAttendee.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MediaViewer from '../components/MediaViewer/MediaViewer';
import FormViewer from '../components/FormViewer/FormViewer';
import { ComChannelContext, initChannel } from '../services/ComChannel';
import Video from '../components/Video';
import ToggleVideoFit from '../components/ToggleVideoFit';
import { ButtonRound } from '../atoms';
import useRemoveLoader from '../components/useRemoveLoader';
import ShareScreenSubscriber from '../components/ShareScreenSubscriber';
import * as rtcClient from '../services/rtcClient';
import ShareScreen from '../components/ShareScreen';
import featureEnabled, { features } from '../services/FeatureFlag';

const feedbackAvailable = featureEnabled(features.ATTENDEE_FEEDBACK);

const AppAttendee = ({ roomId, userName, userEmail }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [media, setMedia] = useState(null);
  const [form, setForm] = useState(null);
  const [screenShareActive, setScreenShareActive] = useState(null);
  const [screenSharePublisher, setScreenSharePublisher] = useState(null);
  const [screenShareAvailable, setScreenShareAvailable] = useState(false);
  const [channel, setChannel] = useState(null);
  const [videoSession, setVideoSession] = useState(null);
  const attendeeReady = {};

  useRemoveLoader();

  useEffect(() => {
    let unsubscribeOnMediaOpen;
    let unsubscribeOnMediaClose;
    let unsubscribeOnFormOpen;
    let unsubscribeOnFormClose;
    let unsubscribeOnTerminateSession;
    let unsubscribeOnOrganizerLoggedOut;
    let unsubscribeOnSessionExpired;
    let unsubscribeOnRoomMetaData;

    if (!channel) {
      initChannel('attendee', undefined, roomId).then(
        (_channel) => {
          setChannel(_channel);

          const roomInfo = _channel.getRoomInfo();

          rtcClient
            .initSession({
              apiKey: roomInfo.apiKey,
              token: roomInfo.token,
              sessionId: roomInfo.sessionId,
              rtcProvider: roomInfo.rtcProvider,
            })
            .then((session) => {
              session
                .checkScreenShareCapability()
                .then((screenShareSupported) => {
                  const metaData = _channel.getRoomMetaData();
                  const roomScreenShareEnabled =
                    metaData && metaData.screenShareEnabled;
                  const attendeeScreenSharingEnabled = featureEnabled(
                    features.ATTENDEE_SCREEN_SHARING
                  );
                  setScreenShareAvailable(
                    screenShareSupported &&
                      roomScreenShareEnabled &&
                      attendeeScreenSharingEnabled
                  );
                  setVideoSession(session);
                });
            });

          unsubscribeOnSessionExpired = _channel.onSessionExpired(() => {
            history.replace('/?e=r810');
            window.location.reload();
          });

          unsubscribeOnMediaOpen = _channel.onMediaOpen((event) => {
            setMedia(event);
            setForm(null);
          });

          unsubscribeOnMediaClose = _channel.onMediaClose(() => {
            setMedia(null);
          });

          unsubscribeOnFormOpen = _channel.onFormOpen((event) => {
            setForm(event);
            setMedia(null);
          });

          unsubscribeOnFormClose = _channel.onFormClose(() => {
            setForm(null);
          });

          unsubscribeOnTerminateSession = _channel.onOrganizerTerminateSession(
            () => {
              handleLogOut();
            }
          );

          unsubscribeOnOrganizerLoggedOut = _channel.onOrganizerLoggedOut(
            () => {
              handleLogOut();
            }
          );

          unsubscribeOnRoomMetaData = _channel.onRoomMetaData((event) => {
            _channel.setRoomMetaData(event);
          });
        },
        (e) => {
          if (e && e.redirect) {
            history.replace(`/?e=${e.message}`);
          }
        }
      );
    }

    return () => {
      if (unsubscribeOnMediaOpen) {
        unsubscribeOnMediaOpen();
      }

      if (unsubscribeOnMediaClose) {
        unsubscribeOnMediaClose();
      }

      if (unsubscribeOnTerminateSession) {
        unsubscribeOnTerminateSession();
      }

      if (unsubscribeOnOrganizerLoggedOut) {
        unsubscribeOnOrganizerLoggedOut();
      }

      if (unsubscribeOnSessionExpired) {
        unsubscribeOnSessionExpired();
      }

      if (unsubscribeOnFormOpen) {
        unsubscribeOnFormOpen();
      }

      if (unsubscribeOnFormClose) {
        unsubscribeOnFormClose();
      }

      if (unsubscribeOnRoomMetaData) {
        unsubscribeOnRoomMetaData();
      }

      if (channel) {
        channel.close();
      }
    };
    // TODO Suppressed warning
    // Line 80:8:  React Hook useEffect has missing dependencies: 'handleLogOut', 'history', and 'roomId'. Either include them or remove the dependency array
    // eslint-disable-next-line
  }, []);

  const handleLogOut = () => {
    if (feedbackAvailable) {
      history.replace({
        pathname: `/feedback?roomId=${roomId}`,
      });
    } else {
      history.replace('/');
    }
    window.location.reload();
  };

  const isVideoFocused = () => !(media || screenShareActive);

  const handleOnScreenShareActive = (active) => {
    setScreenShareActive(active);
  };

  const getStreamName = () => {
    if (userName && userEmail) {
      return `${userName} (${userEmail})`;
    }
    if (userName) {
      return userName;
    }
    if (userEmail) {
      return userEmail;
    }
    return undefined;
  };

  const screenShareButtonDisabled = () =>
    screenSharePublisher || screenShareActive || media || form;

  const handleScreenShare = () => {
    if (screenShareButtonDisabled()) {
      return;
    }
    videoSession.initScreenShare(
      screenSharePublisher,
      (publisher) => {
        setScreenSharePublisher(publisher);
      },
      (publisher) => {
        handleScreenShareUnPublish(publisher);
      }
    );
  };

  const screenShareButtonClassName = screenShareButtonDisabled()
    ? 'semi-transparent'
    : '';

  const handleScreenShareUnPublish = (publisher) => {
    if (publisher) {
      videoSession.getNativeSession().unpublish(publisher);
      setScreenSharePublisher(null);
    }
  };

  return (
    <div data-testid="attendee" className="app-attendee">
      {channel && (
        <ComChannelContext.Provider value={channel}>
          <div data-testid="attendee_main" className="app-attendee-main">
            {videoSession && (
              <Video
                session={videoSession}
                streamName={getStreamName()}
                focused={isVideoFocused()}
              />
            )}

            {videoSession && (
              <ShareScreenSubscriber
                session={videoSession}
                onPublishStreamActive={handleOnScreenShareActive}
              />
            )}

            <ShareScreen
              shareScreenPublished={!!screenSharePublisher}
              onClose={() => handleScreenShareUnPublish(screenSharePublisher)}
            />

            {media && (
              <MediaViewer
                media={media}
                isOrganizer={false}
                attendeeReady={attendeeReady}
              />
            )}

            {form && (
              <div className="card app-attendee-form">
                <FormViewer formData={form} />
              </div>
            )}
          </div>

          <div
            data-testid="attendee_logout"
            className="app-attendee-toolbar items-h-spacer row"
          >
            <ButtonRound
              size="big"
              className="button-round-error"
              onClick={handleLogOut}
            >
              <i className="icon-sign-out" data-tip={t('logout')} />
            </ButtonRound>

            {screenShareAvailable && (
              <ButtonRound
                size="big"
                className={screenShareButtonClassName}
                onClick={handleScreenShare}
              >
                <i
                  className="icon-cast-connected"
                  data-tip={t('shareScreen')}
                />
              </ButtonRound>
            )}
            <ToggleVideoFit />
          </div>
          <ReactTooltip />
        </ComChannelContext.Provider>
      )}
    </div>
  );
};
export default AppAttendee;
