import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { changeLocale, de, enGB } from '../utils/date-fns';

const initI18n = () => {
  registerLocale('de', de);
  registerLocale('en', enGB);

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translations: {
            r404: 'This conversation has already been closed by the organizer.',
            r800: 'You can not join the meeting. The attendee limit for this meeting has been reached.',
            r810: 'Your session has expired.',
            welcome: 'Welcome to yRoom',
            mediaLibrary: 'Media',
            formLibrary: 'Forms',
            notes: 'Notes',
            email: 'E-Mail',
            expiryDate: 'Expiry date',
            sendEmail: 'Send E-Mail',
            emailSent: 'E-Mail sent to {{email}}',
            sessionTransferred: 'Session was reported.',
            close: 'Close',
            cancel: 'Cancel',
            accept: 'Accept',
            language: 'Language',
            joinCall: 'Join the call',
            joinInfo: 'Please type the access code in the input below.',
            joinNameInfo: 'Please type your name in the input below.',
            joinEmailInfo: 'Please type your email in the input below.',
            join: 'Join',
            settings: 'Settings',
            sampleDrop: 'Samples',
            sampleDrop_quantity_validation_error:
              'Please specify sample quantity',
            samplesColumnTitle: 'Product information',
            samplesColumnQuantity: 'QTY',
            samplesGenerateForm: 'Generate Sample Form',
            toggleVideo: 'Toggle video',
            toggleAudio: 'Toggle audio',
            videoFitMode: 'Video fit mode',
            videoFitModeCover: 'Cover',
            videoFitModeContain: 'Contain',
            cycleCamera: 'Cycle camera',
            logout: 'Logout',
            logoutDescription:
              'Complete the session to report an activity in the CRM. Cancelling will delete all collected data.',
            clientInvite: 'Invite attendee',
            clientURL: 'URL',
            clientPassword: 'Access code',
            clientSendEmail: 'Send link via email',
            roomStatus: 'Room status',
            closed: 'Closed',
            active: 'Active',
            roomComplete: 'Complete and save',
            roomCancel: 'Cancel and do not save',
            roomContinueLater: 'Continue later',
            connected: 'Connected',
            tryingToConnect: 'Trying to connect ...',
            previousPage: 'Previous page',
            nextPage: 'Next page',
            organizerInfo: 'Organizer info',
            organizerJoined: 'Organizer joined',
            organizerLeft: 'Organizer left',
            attendeeJoined: 'Attendee joined',
            attendeeLeft: 'Attendee left',
            attendeeMediaReady: '{{ready}} of {{total}} ready',
            confirm: 'Please confirm',
            disconnectClient: 'Remove attendee',
            search: 'Search... at least 3 chars',
            generalError: 'An unknown error occurred.',
            generalNetworkError: 'A network error occurred.',
            confirmDisconnectClient:
              'Do you really want to remove attendee from the session?',
            sessionEnded: 'The session has ended. You can close this window.',
            joinRoom: 'Join Room',
            cancelRoom: 'Delete Room',
            createRoom: 'Create Room',
            planRoom: 'Plan Room',
            inProgressRooms: 'Active Rooms',
            plannedRooms: 'Planned Rooms',
            roomId: 'Room ID',
            connectionStatus: 'Connection Status',
            pcTest: 'Pre-call Test',
            'pcDescription.title':
              'Before joining the room we would like you to run the Pre-call test to ensure that all preconditions are met.',
            'pcDescription.item1':
              'When prompted, allow us to use your camera and audio hardware.',
            'pcDescription.item2':
              'Make sure you have a good internet connection to use yRoom.',
            pcRunTest: 'Run Test to Join',
            pcRunRetest: 'Test again',
            organisatorLogin: 'Login',
            alternateLogin: 'Please select an alternate login mechanism:',
            ysuraLogin: 'ysura',
            localLogin: 'local',
            formAttendeeCancel: 'Cancel',
            formAttendeeSubmit: 'Submit',
            formNotValid: 'The form is not valid. Please check your entries.',
            formCancelled: 'The form was cancelled.',
            formSubmitted: 'The form was submitted.',
            formInstanceTransferred: 'The form was transferred.',
            formsValidationKnownAttendee: 'Forms require registered attendee',
            videoPublisherGeneralError:
              'The video publisher failed to initialize.',
            videoPublisherScreenSharingError:
              'The screen sharing failed due to missing permissions.',
            videoSubscriptionGeneralError:
              "The video of an attendee can't be shown.",
            videoSessionGeneralError: "The video session can't be initialized.",
            attendees: 'Attendees',
            startTime: 'Start Time',
            organizerLink: 'Organizer Link',
            attendeeLink: 'Attendee Link',
            room: 'Room',
            roomName: 'Room Name',
            noEntries: 'no entries',
            copiedToClipboard: 'Copied to clipboard',
            roomCancelPrompt: 'Do you want to delete this room?',
            chat: 'Chat',
            attendeeManagement: 'Attendees',
            cleanStage: 'Clean Stage',
            shareScreen: 'Share screen',
            screenShareActive: 'Screen sharing is active',
            startDate: 'Start Date',
            pending: 'Pending',
            sampleCountError: 'A minimum of {{min}} samples is required.',
            noSamples:
              'A drop of virtual samples is not possible or contigent is exceeded for this HCP.',
            sampleDropAlreadySubmitted: 'The sample drop was already submitted',
            thankYou: 'Thank you for participating',
            rateYourExperience:
              'How would you rate your experience in this yRoom call?',
            feedbackPlaceholder:
              'Please explain what went well and what did not.',
            feedbackSuccess: 'Feedback submitted',
            submit: 'Submit',
          },
        },
        de: {
          translations: {
            r404: 'Diese Konversation wurde bereits durch den Organisator geschlossen.',
            r800: 'Sie können nicht teilnehmen. Die maximale Teilnehmeranzahl ist bereits erreicht.',
            r810: 'Ihre Session ist abgelaufen.',
            welcome: 'Willkommen im yRoom',
            mediaLibrary: 'Medien',
            formLibrary: 'Formulare',
            notes: 'Notizen',
            close: 'Schließen',
            cancel: 'Abbrechen',
            accept: 'Akzeptieren',
            email: 'E-Mail',
            sendEmail: 'E-Mail verschicken',
            emailSent: 'E-Mail wurde an {{email}} verschickt',
            expiryDate: 'Verfallsdatum',
            sessionTransferred: 'Session wurde gespeichert',
            language: 'Sprache',
            joinCall: 'Dem Videocall beitreten',
            joinInfo:
              'Fügen Sie bitte den Zugangscode in das untenstehende Feld ein, um dem Anruf beizutreten.',
            joinNameInfo:
              'Bitte geben Sie Ihren Namen in die unten stehende Eingabe ein.',
            joinEmailInfo:
              'Bitte geben Sie Ihre E-Mail-Adresse in die unten stehende Eingabe ein.',
            join: 'Beitreten',
            settings: 'Einstellungen',
            sampleDrop: 'Muster',
            sampleDrop_quantity_validation_error:
              'Bitte geben Sie die Musteranzahl ein.',
            samplesColumnTitle: 'Produkt',
            samplesColumnQuantity: 'Anz.',
            samplesGenerateForm: 'Abgabeformular generieren',
            videoFitMode: 'Videodarstellung',
            videoFitModeCover: 'Ausfüllen',
            videoFitModeContain: 'Einpassen',
            toggleVideo: 'Video umschalten',
            toggleAudio: 'Audio umschalten',
            cycleCamera: 'Kamera fahren',
            logout: 'Abmeldung',
            logoutDescription:
              'Schließen Sie die Session ab um eine Aktivität im CRM zu berichten. Beim Abbrechen gehen alle gesammelten Daten verloren.',
            clientInvite: 'Teilnehmer einladen',
            clientURL: 'URL',
            clientPassword: 'Zugangscode',
            clientSendEmail: 'Link per E-Mail verschicken',
            roomStatus: 'Status des Raums',
            closed: 'Geschlossen',
            active: 'Aktiv',
            roomComplete: 'Abschließen und speichern',
            roomCancel: 'Abbrechen ohne zu speichern',
            roomContinueLater: 'Später fortführen',
            connected: 'Verbunden',
            tryingToConnect: 'Versuche eine Verbindung herzustellen...',
            previousPage: 'Vorherige Seite',
            nextPage: 'Nächste Seite',
            organizerInfo: 'Organisator',
            organizerJoined: 'Organisator ist beigetreten',
            organizerLeft: 'Organisator hat die Sitzung verlassen',
            attendeeJoined: 'Teilnehmer ist beigetreten',
            attendeeLeft: 'Teilnehmer hat die Sitzung verlassen',
            attendeeMediaReady: '{{ready}} von {{total}} bereit',
            confirm: 'Bitte bestätigen',
            disconnectClient: 'Teilnehmer entfernen',
            search: 'Suche... mindestens 3 Zeichen',
            generalError: 'Ein unbekannter Fehler ist aufgetreten.',
            generalNetworkError: 'Ein Netzwerkfehler ist aufgetreten.',
            confirmDisconnectClient:
              'Wollen Sie wirklich den Teilnehmer aus der Sitzung entfernen?',
            sessionEnded:
              'Die Sitzung wurde beendet. Sie können dieses Fenster schließen.',
            joinRoom: 'Raum beitreten',
            cancelRoom: 'Raum löschen',
            createRoom: 'Raum erstellen',
            planRoom: 'Raum planen',
            inProgressRooms: 'Aktive Räume',
            plannedRooms: 'Geplante Räume',
            roomId: 'Raum ID',
            connectionStatus: 'Verbindungsstatus',
            pcTest: 'System und Verbindungstest',
            'pcDescription.title':
              'Bitte führen Sie vor dem Betreten des Raums den Systemtest durch, um die technischen Vorraussetzungen zu prüfen.',
            'pcDescription.item1':
              'Erlauben Sie uns, Ihre Kamera- und Audio-Hardware zu verwenden, wenn Sie dazu aufgefordert werden.',
            'pcDescription.item2':
              'Stellen Sie sicher, dass Sie eine gute und stabile Internet Verbindung haben, um den yRoom zu nutzen.',
            pcRunTest: 'Test starten um beizutreten',
            pcRunRetest: 'Test wiederholen',
            organisatorLogin: 'Login',
            alternateLogin:
              'Bitte wählen Sie einen alternativen Authentifizierungsmechanismus:',
            ysuraLogin: 'ysura',
            localLogin: 'lokal',
            formAttendeeCancel: 'Abbrechen',
            formAttendeeSubmit: 'Abschicken',
            formNotValid:
              'Das Formular ist nicht korrekt. Bitte prüfen Sie die Eingaben.',
            formCancelled: 'Das Ausfüllen des Formular wurde abgebrochen.',
            formSubmitted: 'Das Formular wurde abgeschickt.',
            formInstanceTransferred:
              'Das Formular wurde an das CRM erfolgreich übertragen.',
            formsValidationKnownAttendee:
              'Formulare benötigen einen bekannten Teilnehmer',
            videoPublisherGeneralError:
              'Die Videoübertragung konnte nicht initialisiert werden.',
            videoPublisherScreenSharingError:
              'Das Teilen des Bildschirms ist wegen mangelnder Berechtigungen fehlgeschlagen.',
            videoSubscriptionGeneralError:
              'Das Video eines Teilnehmers kann nicht angezeigt werden.',
            videoSessionGeneralError:
              'Die Videosession konnten nicht initalisiert werden.',
            attendees: 'Teilnehmer',
            startTime: 'Startzeitpunkt',
            organizerLink: 'Link für Organisator',
            attendeeLink: 'Link für Teilnehmer',
            room: 'Raum',
            roomName: 'Raumname',
            noEntries: 'keine Einträge',
            copiedToClipboard: 'In die Zwischenablage kopiert',
            roomCancelPrompt: 'Wollen Sie diesen Raum löschen?',
            chat: 'Chat',
            attendeeManagement: 'Teilnehmer',
            cleanStage: 'Aufräumen',
            shareScreen: 'Bildschirm teilen',
            screenShareActive: 'Die Bildschirmfreigabe ist aktiv',
            startDate: 'Startdatum',
            pending: 'Ausstehende',
            sampleCountError: 'Eine Anzahl von min. {{min}} ist erforderlich.',
            noSamples:
              'Für diesen HCP ist keine Abgabe von virtuellen Mustern mehr möglich oder das Kontingent ist bereits ausgeschöpft.',
            sampleDropAlreadySubmitted:
              'Die Musteranforderung wurde bereits durchgeführt',
            thankYou: 'Vielen Dank für Ihre Teilnahme',
            rateYourExperience:
              'Wie würden Sie Ihre Erfahrung mit diesem yRoom-Anruf bewerten?',
            feedbackPlaceholder:
              'Bitte erläutern Sie, was gut gelaufen ist und was nicht.',
            feedbackSuccess: 'Bewertung versandt',
            submit: 'Absenden',
          },
        },
      },
      fallbackLng: 'en',
      debug: false,

      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false, // we use content as keys

      interpolation: {
        escapeValue: false,
      },
    });

  i18n.on('languageChanged', (language) => {
    changeLocale(language);
    setDefaultLocale(language);
  });

  changeLocale(i18n.language);
  setDefaultLocale(i18n.language);
};

export { initI18n };
