import React from 'react';
import './Loader.scss';

export const Loader = ({ loading }) => {
  return (
    loading && (
      <div className="ld">
        <div className="ld-animated ld-animated-small" />
      </div>
    )
  );
};
