import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './PanelStack.scss';
import PanelView from './PanelView';

const PANEL_STACK_CLASS = 'panel-stack';

const getStack = (stack, initialPanel) => {
  if (stack !== null) {
    return stack.slice().reverse();
  }

  if (initialPanel !== undefined) {
    return [initialPanel];
  }

  return [];
};

const PanelStack = ({
  stack,
  initialPanel,
  onOpen,
  onClose,
  renderActivePanelOnly = true,
  showPanelHeader = true,
  className,
}) => {
  const [state, setState] = useState({
    direction: 'push',
    stack: getStack(stack, initialPanel),
  });

  const renderPanels = () => {
    if (state.stack && state.stack.length === 0) {
      return null;
    }

    const panelsToRender = renderActivePanelOnly ? [stack[0]] : stack;
    return panelsToRender.map(renderPanel).reverse();
  };

  const renderPanel = (panel, index) => {
    const layer = state.stack.length - index;
    const key = renderActivePanelOnly ? state.stack.length : layer;

    return (
      <CSSTransition classNames={PANEL_STACK_CLASS} key={key} timeout={400}>
        <PanelView
          onClose={handlePanelClose}
          onOpen={handlePanelOpen}
          panel={panel}
          previousPanel={state.stack[index + 1]}
          showHeader={showPanelHeader}
        />
      </CSSTransition>
    );
  };

  const handlePanelClose = (panel) => {
    if (state.stack[0] !== panel || state.stack.length <= 1) {
      return;
    }

    if (onClose) {
      onClose(panel);
    }

    if (!stack) {
      setState((stateObj) => ({
        direction: 'pop',
        stack: stateObj.stack.slice(1),
      }));
    }
  };

  const handlePanelOpen = (panel) => {
    if (onOpen) {
      onOpen(panel);
    }

    if (!stack) {
      setState((stateObj) => ({
        direction: 'push',
        stack: [panel, ...stateObj.stack],
      }));
    }
  };

  return (
    <TransitionGroup
      className={`${PANEL_STACK_CLASS} ${PANEL_STACK_CLASS}-${state.direction} ${className}`}
      component="div"
    >
      {renderPanels()}
    </TransitionGroup>
  );
};

export default PanelStack;
