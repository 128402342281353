import React from 'react';
import browser from '../../services/Browser';
import './PointerSvg.scss';

const PointerSvg = ({ posX, posY, isOrganizer, completionCallback }) => {
  // subtract 50px to center the circle (circle has a radius of 50)
  const realPosX = posX - 50;
  const realPosY = posY - 50;

  setTimeout(() => completionCallback(), 1000);

  return (
    <div
      data-testid="pointer"
      style={{
        position: 'fixed',
        left: `${realPosX}px`,
        top: `${realPosY}px`,
        width: '100px',
        height: '100px',
      }}
      className={isOrganizer ? 'pointer-organizer' : 'pointer-attendee'}
    >
      {!browser.isEdgeLegacy && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx="50" cy="50" r="0" strokeWidth="5" fill="none">
            <animate
              attributeName="r"
              begin="0s"
              dur="1s"
              repeatCount="once"
              from="0"
              to="50"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
          <circle cx="50" cy="50" r="0" strokeWidth="3" fill="none">
            <animate
              attributeName="r"
              begin="0.2s"
              dur="0.8s"
              repeatCount="once"
              from="0"
              to="40"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
          <circle cx="50" cy="50" r="0" strokeWidth="1" fill="none">
            <animate
              attributeName="r"
              begin="0.4s"
              dur="0.6s"
              repeatCount="once"
              from="0"
              to="30"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
        </svg>
      )}
      {browser.isEdgeLegacy && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx="50" cy="50" r="45" strokeWidth="5" fill="none" />
          <circle cx="50" cy="50" r="35" strokeWidth="3" fill="none" />
          <circle cx="50" cy="50" r="25" strokeWidth="1" fill="none" />
        </svg>
      )}
    </div>
  );
};

export default PointerSvg;
