import React from 'react';
import { useTranslation } from 'react-i18next';

import './ShareScreen.scss';
import ReactTooltip from 'react-tooltip';
import { AsyncButton } from '../atoms';

const ShareScreen = ({ shareScreenPublished, onClose }) => {
  const { t } = useTranslation();
  const style = shareScreenPublished ? 'screen-share-active' : null;
  return (
    <div className={`screen-share ${style}`}>
      {shareScreenPublished && (
        <div className="screen-share-toolbar">
          <div className="h-spacer-big">{t('screenShareActive')}</div>
          <AsyncButton className="button-error" onClick={onClose}>
            {t('close')}
          </AsyncButton>
        </div>
      )}

      <ReactTooltip />
    </div>
  );
};

export default ShareScreen;
