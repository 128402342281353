const TEST_DOMAINS = ['localhost', '.dev.yroom.app'];

export const features = {
  SCHEDULING: 'SCHEDULING',
  MULTI_ATTENDEES: 'MULTI_ATTENDEES',
  NEW_ATTENDEE_MGMT: 'NEW_ATTENDEE_MGMT',
  SAMPLES: 'SAMPLES',
  ATTENDEE_SCREEN_SHARING: 'ATTENDEE_SCREEN_SHARING',
  ATTENDEE_FEEDBACK: 'ATTENDEE_FEEDBACK',
  MEDIA_TIME_TRACKING: 'MEDIA_TIME_TRACKING',
};

const FEATURE_MAP = {
  [features.SCHEDULING]: [
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'release-dsde.yroom.app',
    'release-ashfield-de.yroom.app',
    'bms-ashfield.yroom.app',
    'sanofi-pd.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
    'training-isarlife-de.yroom.app',
    'remotejn-ashfield.yroom.app',
  ],
  [features.MULTI_ATTENDEES]: [
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'nnp.yroom.eu',
    'ainet.yroom.app',
    'sanofi-pd.yroom.app',
    'training-isarlife-de.yroom.app',
  ],
  // Currently not mature, do not use.
  [features.NEW_ATTENDEE_MGMT]: [],
  [features.SAMPLES]: [
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'dsde.yroom.app',
    'release-dsde.yroom.app',
    'training-dsde.yroom.app',
    'release-ashfield-de.yroom.app',
    'sanofi-pd.yroom.app',
    'training-isarlife-de.yroom.app',
  ],
  [features.ATTENDEE_SCREEN_SHARING]: [
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'bms-ashfield.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
    'training-isarlife-de.yroom.app',
  ],
  [features.ATTENDEE_FEEDBACK]: [
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'bms-ashfield.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
    'training-isarlife-de.yroom.app',
  ],
  [features.MEDIA_TIME_TRACKING]: [
    '-ashfield.yroom.app',
    'dummy.yroom.app',
    'isarlife-de.yroom.app',
    'isarlife-en.yroom.app',
    'release-ashfield-de.yroom.app',
    'training-isarlife-de.yroom.app',
  ],
};

const featureEnabled = (feature) => {
  if (!feature) {
    return false;
  }

  const { href } = window.location;
  // eslint-disable-next-line
  for (const str of TEST_DOMAINS) {
    if (href.includes(str)) {
      return true;
    }
  }

  if (!FEATURE_MAP[feature]) {
    return false;
  }

  // eslint-disable-next-line
  for (const str of FEATURE_MAP[feature]) {
    if (href.includes(str)) {
      return true;
    }
  }

  return false;
};

export default featureEnabled;
