import React, { useCallback, useContext, useEffect } from 'react';
import PdfViewer from './PdfViewer';
import HtmlViewer from './HtmlViewer';
import { ComChannelContext } from '../../services/ComChannel';
import './MediaViewer.scss';

const MediaViewer = ({ media, isOrganizer, onClose, attendeeReady }) => {
  const channel = useContext(ComChannelContext);
  const type = media.contentDocument.mediaType;
  const isHtml = type === 'text/html';
  const isPdf = type === 'application/pdf';
  const unsupportedMedia = !isHtml && !isPdf;

  const handleOnClose = useCallback(() => {
    if (isOrganizer) {
      channel.broadcastOrganizerMediaClose(media);
    }
    onClose();
  }, [media, channel, isOrganizer, onClose]);

  const handleWindowMessage = useCallback(
    (event) => {
      try {
        if (!isOrganizer) {
          return;
        }
        const data =
          event.data &&
          typeof event.data === 'string' &&
          (event.data.indexOf('reveal') > 0 ||
            event.data.indexOf('pdfViewer') > 0) &&
          JSON.parse(event.data);
        if (data) {
          channel.broadcastOrganizerPresentationStateChange(data, media.id);
        }
      } catch (e) {
        console.error(`handleWindowMessage Error:${JSON.stringify(e)}`);
      }
    },
    [channel, isOrganizer, media]
  );

  useEffect(() => {
    if (isOrganizer && media) {
      window.addEventListener('message', handleWindowMessage);
      channel.broadcastOrganizerMediaOpen(media);
    }

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [media, isOrganizer, channel, handleWindowMessage]);

  return (
    <div className="media-viewer">
      <div className="media-viewer-content">
        {isHtml && (
          <HtmlViewer
            isOrganizer={isOrganizer}
            media={media}
            onClose={handleOnClose}
            onStateChange={handleWindowMessage}
            attendeeReady={attendeeReady}
          />
        )}
        {isPdf && (
          <PdfViewer
            isOrganizer={isOrganizer}
            media={media}
            onClose={handleOnClose}
            onStateChange={handleWindowMessage}
            attendeeReady={attendeeReady}
          />
        )}
        {unsupportedMedia && <div>Unsupported Media format</div>}
      </div>
    </div>
  );
};

export default MediaViewer;
