import * as $ from 'jquery';
import getLayout from './layoutHelper';

const fixAspectRatio = (elem, width) => {
  const sub = elem.querySelector('.OT_root');
  if (sub) {
    const oldWidth = sub.style.width;
    sub.style.width = `${width}px`;
    sub.style.width = oldWidth || '';
  }
};

const getChildDims = (child) => {
  if (child) {
    if (child.videoHeight && child.videoWidth) {
      return {
        height: child.videoHeight,
        width: child.videoWidth,
      };
    }
    const video = child.querySelector('video');
    if (video && video.videoHeight && video.videoWidth) {
      return {
        height: video.videoHeight,
        width: video.videoWidth,
      };
    }
  }
  return {
    height: 480,
    width: 640,
  };
};

const uuid = () => {
  return (Math.random() * 100000000).toFixed(0);
};

const layout = (container, opts) => {
  const css = (el, propertyName, value) => {
    if (value) {
      // eslint-disable-next-line
      el.style[propertyName] = value;
      return NaN;
    }
    if (typeof propertyName === 'object') {
      Object.keys(propertyName).forEach((key) => {
        css(el, key, propertyName[key]);
      });
      return NaN;
    }
    const computedStyle = ((opts && opts.window) || window).getComputedStyle(
      el
    );
    let currentValue = computedStyle.getPropertyValue(propertyName);

    if (currentValue === '') {
      currentValue = el.style[propertyName];
    }

    return currentValue;
  };

  const filterDisplayNone = (element) => css(element, 'display') !== 'none';

  const height = (el) => {
    return el.offsetHeight > 0 ? `${el.offsetHeight}px` : css(el, 'height');
  };

  const width = (el) => {
    return el.offsetWidth > 0 ? `${el.offsetWidth}px` : css(el, 'width');
  };

  const positionElement = function positionElement(elem, x, y, w, h, animate) {
    const targetPosition = {
      left: `${x}px`,
      top: `${y}px`,
      width: `${w}px`,
      height: `${h}px`,
    };

    if (animate && $) {
      $(elem).stop();
      $(elem).animate(
        targetPosition,
        animate.duration || 200,
        animate.easing || 'swing',
        () => {
          fixAspectRatio(elem, w);
          if (animate.complete) {
            animate.complete.call(this);
          }
        }
      );
    } else {
      css(elem, targetPosition);
      if (!elem.classList.contains('ot-layout')) {
        elem.classList.add('ot-layout');
      }
    }
    fixAspectRatio(elem, w);
  };

  const getCSSNumber = function getCSSNumber(elem, prop) {
    const cssStr = css(elem, prop);
    return cssStr ? parseInt(cssStr, 10) : 0;
  };

  const getHeight = function getHeight(elem) {
    const heightStr = height(elem);
    return heightStr ? parseInt(heightStr, 10) : 0;
  };

  const getWidth = function getWidth(elem) {
    const widthStr = width(elem);
    return widthStr ? parseInt(widthStr, 10) : 0;
  };

  const { animate = false, ignoreClass = 'OT_ignore' } = opts;

  if (css(container, 'display') === 'none') {
    return;
  }
  let id = container.getAttribute('id');
  if (!id) {
    id = `OT_${uuid()}`;
    container.setAttribute('id', id);
  }

  // eslint-disable-next-line
  opts.containerHeight =
    getHeight(container) -
    getCSSNumber(container, 'borderTop') -
    getCSSNumber(container, 'borderBottom');

  // eslint-disable-next-line
  opts.containerWidth =
    getWidth(container) -
    getCSSNumber(container, 'borderLeft') -
    getCSSNumber(container, 'borderRight');

  const children = Array.prototype.filter.call(
    container.querySelectorAll(`#${id}>*:not(.${ignoreClass})`),
    filterDisplayNone
  );

  const elements = children.map((element) => getChildDims(element));
  const boxes = getLayout(opts, elements);

  boxes.forEach((box, idx) => {
    const elem = children[idx];
    css(elem, 'position', 'absolute');
    const actualWidth =
      box.width -
      getCSSNumber(elem, 'paddingLeft') -
      getCSSNumber(elem, 'paddingRight') -
      getCSSNumber(elem, 'marginLeft') -
      getCSSNumber(elem, 'marginRight') -
      getCSSNumber(elem, 'borderLeft') -
      getCSSNumber(elem, 'borderRight');

    const actualHeight =
      box.height -
      getCSSNumber(elem, 'paddingTop') -
      getCSSNumber(elem, 'paddingBottom') -
      getCSSNumber(elem, 'marginTop') -
      getCSSNumber(elem, 'marginBottom') -
      getCSSNumber(elem, 'borderTop') -
      getCSSNumber(elem, 'borderBottom');

    positionElement(
      elem,
      box.left,
      box.top,
      actualWidth,
      actualHeight,
      animate
    );
  });
};

export default layout;
