import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClient } from '../services/ApiClient';
import { Label, PanelStack, SearchBox } from '../atoms';
import './AttendeeManagement.scss';

const AttendeeInvitationPanel = () => {
  return <h4>Invitation Panel</h4>;
};

const AttendeeManagementPanel = ({ openPanel }) => {
  const [attendees, setAttendees] = useState([]);
  const { t } = useTranslation();

  const handleOpenAttendeeInvitationPanel = () => {
    openPanel({ component: AttendeeInvitationPanel, title: `Invite Attendee` });
  };

  const handleOnMenuItemClick = () => {};

  // TODO To be determined
  const menuItems = [
    { id: '1', label: t('Option 1'), onClick: handleOnMenuItemClick },
    { id: '2', label: t('Option 2'), onClick: handleOnMenuItemClick },
  ];

  const getAttendeeList = (search, offset) => {
    apiClient.getAttendeeList(search, offset).then((res) => {
      const result = res.map((it) => {
        return {
          id: it.id,
          name: it.displayName,
          description: it.email,
        };
      });
      const total = [...attendees, ...result];
      setAttendees(total);
    });
  };

  const clearAttendedList = () => {
    setAttendees([]);
  };

  return (
    <section className="column h100">
      <div className="attendee-management-header">
        <div className="attendee-management-header-content h-spacer">
          <i className="icon-attendeemgmt h-spacer" />
          <b className="h-spacer">
            <Label keyId="attendee" />
          </b>
          {/* eslint-disable-next-line */}
          <i
            className="icon-plus h-spacer plus"
            onClick={handleOpenAttendeeInvitationPanel}
          />
        </div>
        <div className="attendee-management-header-icons">
          <i className="icon-mic-item-off" />
        </div>
      </div>

      <SearchBox
        items={attendees}
        menuItems={menuItems}
        searchFunction={getAttendeeList}
        clearFunction={clearAttendedList}
      />
    </section>
  );
};

const AttendeeManagement = () => {
  const { t } = useTranslation();

  const [state] = useState({
    panelStack: [{ component: AttendeeManagementPanel, title: t('attendees') }],
  });

  return (
    <PanelStack
      className="attendee-management"
      initialPanel={state.panelStack[0]}
    />
  );
};

export default AttendeeManagement;
