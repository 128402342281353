import React, { useState, useEffect, useRef } from 'react';

const AsyncButton = ({ ...props }) => {
  const [asyncState, setAsyncState] = useState(null);
  const disabled = asyncState === 'pending' || props.disabled;

  const handleClick = useRef(() => {});

  useEffect(() => {
    let canceled = false;

    handleClick.current = (...args) => {
      setAsyncState('pending');
      const returnFn = props.onClick.apply(null, args);
      if (returnFn && typeof returnFn.then === 'function') {
        returnFn.then().finally(() => {
          if (!canceled) {
            setAsyncState('fulfilled');
          }
        });
      } else {
        setAsyncState('fulfilled');
      }
    };

    return () => {
      canceled = true;
    };
  }, [props.onClick]);

  return (
    <button
      {...props}
      type="button"
      className={['yr-button', props.className].join(' ')}
      disabled={asyncState === 'pending' || props.disabled}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      onClick={(event) => {
        handleClick.current(event);
      }}
    >
      {props.children}
    </button>
  );
};

export { AsyncButton };
