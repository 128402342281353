import React from 'react';
import { Markup } from 'interweave';
import './SimpleList.scss';

const SimpleList = ({ title, items = [] }) => {
  return (
    <div className="simple-list">
      <div className="simple-list-title">{title}</div>
      {items.map((it, index) => {
        return (
          // eslint-disable-next-line
          <div key={index}>
            <Markup content={it} />
          </div>
        );
      })}
    </div>
  );
};

export { SimpleList };
