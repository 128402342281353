import React, { useContext } from 'react';
import { AsyncButton, Label } from '../atoms';
import { ComChannelContext } from '../services/ComChannel';

const DisconnectClient = ({ onClose }) => {
  const channel = useContext(ComChannelContext);

  const broadcastDisconnectClient = () => {
    channel.broadcastDisconnectClient();
    if (onClose) {
      onClose();
    }
  };

  return (
    <section className="column h100">
      <b>
        <Label keyId="disconnectClient" />
      </b>
      <div>
        <Label keyId="confirmDisconnectClient" />
      </div>
      <br />
      <div className="column column-center column-space-around">
        <AsyncButton
          onClick={broadcastDisconnectClient}
          className="button-error"
        >
          <Label keyId="disconnectClient" />
        </AsyncButton>
        <AsyncButton
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          default
        >
          <Label keyId="close" />
        </AsyncButton>
      </div>
    </section>
  );
};

export default DisconnectClient;
