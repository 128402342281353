import gql from 'graphql-tag';

const prepareSampleDrop = () => {
  return gql`
    mutation ($configuration: SampleDropConfigurationInput!) {
      prepareSampleDrop(configuration: $configuration) {
        id
        html
      }
    }
  `;
};

const submitSampleDrop = () => {
  return gql`
    mutation ($sampleDrop: SampleDropInput!) {
      submitSampleDrop(sampleDrop: $sampleDrop) {
        id
      }
    }
  `;
};

const transferSampleDrop = (sampleDropId) => {
  return gql`
    mutation {
      transferSampleDrop(sampleDropId: "${sampleDropId}") {
        id
      }
    }
  `;
};

const addFeedback = (id, roomId, rating, text) => {
  return gql`
    mutation {
      addFeedback(id: "${id}", roomId: "${roomId}", rating: "${rating}", text: "${text}") {
        id
        userName
        submittedAt
        roomId
        rating
        text
      }
    }
  `;
};

const trackMedia = () => {
  return gql`
    mutation ($mediaTracking: MediaTrackingInput!) {
      trackMedia(mediaTracking: $mediaTracking)
    }
  `;
};

export {
  addFeedback,
  prepareSampleDrop,
  submitSampleDrop,
  trackMedia,
  transferSampleDrop,
};
