import { useEffect, useState } from 'react';
import apiRest from '../services/ApiRestClient';

const useAttendeeAuthentication = (roomId, history) => {
  const [room, setRoom] = useState(null);

  useEffect(() => {
    apiRest.getRoom(roomId).then((roomData) => {
      const id = roomData && roomData.id;
      setRoom(id ? roomData : null);
      if (!id) {
        history.replace('/?e=r404');
        window.location.reload();
      }
    });
    // TODO Suppressed warning
    // Line 18:8:  React Hook useEffect has missing dependencies: 'history' and 'roomId'. Either include them or remove the dependency array  react-hooks/exhaustive-deps
    // eslint-disable-next-line
  }, []);

  return room;
};

export default useAttendeeAuthentication;
