import React from 'react';
import './AttendeeOrganization.scss';

const AttendeeOrganization = ({ organization }) => {
  const fixLink = (link) => {
    const trimmedLink = link.trim();
    if (trimmedLink.startsWith('http')) {
      return trimmedLink;
    }

    return `http://${trimmedLink}`;
  };

  return (
    <div className="attendee-organization">
      <div className="attendee-organization-title">
        {organization.displayName}
      </div>
      {organization.address && organization.postalCode && organization.city && (
        <div>
          {organization.address}, {organization.postalCode} {organization.city}
        </div>
      )}
      {organization.phone && (
        <div className="row">
          <i className="icon-phone icon-smaller">&nbsp;</i>{' '}
          <a href={`tel:${organization.phone}`}>{organization.phone}</a>
        </div>
      )}
      {organization.fax && (
        <div className="row">
          <i className="icon-fax icon-smaller">&nbsp;</i>{' '}
          <a href={`tel:${organization.fax}`}>{organization.fax}</a>
        </div>
      )}
      {organization.email && (
        <div className="row">
          <i className="icon-email icon-smaller">&nbsp;</i>{' '}
          <a href={`mailto:${organization.email}`}>{organization.email}</a>
        </div>
      )}
      {organization.website && (
        <div className="row">
          <i className="icon-home icon-smaller">&nbsp;</i>{' '}
          <a
            href={fixLink(organization.website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fixLink(organization.website)}
          </a>
        </div>
      )}
    </div>
  );
};

export default AttendeeOrganization;
