import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRemoveLoader from '../../components/useRemoveLoader';
import callImg from '../../images/video-call.png';
import apiRest from '../../services/ApiRestClient';
import { ReactComponent as StarIcon } from '../../svg/icons/star.svg';
import styles from './Feedback.module.scss';

export const Feedback = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { roomId } = queryString.parse(history.location.search);
  const [rating, setRating] = useState(0);
  const [note, setNote] = useState('');

  useRemoveLoader();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <i className="icon-logo" />

        <img className="welcome-img" src={callImg} alt="" />
      </div>

      <div className={styles.content}>
        <h1>{t('thankYou')}</h1>

        <div className={styles.container}>
          <div>{t('rateYourExperience')}</div>

          <div className={styles.starContainer}>
            {Array.from(new Array(5)).map((_, idx) => (
              <button
                key={`button-rating-${idx + 1}`}
                type="button"
                tabIndex={5 - idx + 1}
                data-testid={`button-rating-${idx + 1}`}
                className={idx + 1 >= rating && rating > 0 ? styles.active : ''}
                onClick={() => setRating(idx + 1)}
              >
                <StarIcon />
              </button>
            ))}
          </div>

          {rating > 0 && (
            <textarea
              value={note}
              rows={10}
              data-testid="text-feedback"
              onChange={(event) => setNote(event.target.value)}
              placeholder={t('feedbackPlaceholder')}
            />
          )}

          <div className={styles.buttonContainer}>
            <button
              type="button"
              className="yr-button"
              data-testid="button-feedback-close"
              onClick={() => {
                history.replace('/');
              }}
            >
              {t('close')}
            </button>

            <button
              type="button"
              disabled={!rating}
              className="yr-button"
              data-testid="button-feedback-submit"
              onClick={() => {
                apiRest
                  .addFeedback({
                    roomId,
                    rating: 5 - rating + 1,
                    text: note,
                  })
                  .then(() => {
                    toast(t('feedbackSuccess'), {
                      type: toast.TYPE.SUCCESS,
                    });
                    history.replace('/');
                  });
              }}
            >
              {t('submit')}
            </button>
          </div>
        </div>

        <div className={`column-vh-center margin-bigger ${styles.footer}`}>
          <span>ysura GmbH &#8226; Metzstr. 14b &#8226; 81667 München</span>
          <a href="https://www.ysura.com/">www.ysura.com</a>
        </div>
      </div>
    </div>
  );
};
