/* eslint-disable class-methods-use-this */
import React from 'react';
import { OpenVidu } from 'openvidu-browser';

export class SessionOV {
  session;

  OV;

  onSubscriberConnected;

  onSubscriberDisconnected;

  constructor() {
    this.OV = new OpenVidu();
    this.OV.enableProdMode();
    this.session = this.OV.initSession();
    this.session.on('streamDestroyed', () => {
      if (this.onSubscriberDisconnected) {
        this.onSubscriberDisconnected();
      }
    });

    this.session.on('streamCreated', () => {
      if (this.onSubscriberConnected) {
        this.onSubscriberConnected();
      }
    });
    this.handleSpeechIndicator(this.session);
  }

  handleSpeechIndicator(session) {
    session.on('publisherStartSpeaking', (event) => {
      const videoWidget = document.querySelector(
        `[stream-id="${event.streamId}"]`
      );
      if (videoWidget) {
        videoWidget.classList.add('video-subscriber-audio-active');
      }
    });

    session.on('publisherStopSpeaking', (event) => {
      const videoWidget = document.querySelector(
        `[stream-id="${event.streamId}"]`
      );
      if (videoWidget) {
        videoWidget.classList.remove('video-subscriber-audio-active');
      }
    });
  }

  injectVideoWidget(subject, containerSelector, cssClass) {
    const container = document.getElementById(containerSelector);
    const wrapper = document.createElement('div');
    wrapper.setAttribute('class', cssClass);
    wrapper.setAttribute('stream-id', subject.stream.streamId);

    const videoPoster = document.createElement('div');
    videoPoster.setAttribute('class', 'OT_video-poster');

    const audioPoster = document.createElement('i');
    audioPoster.setAttribute(
      'class',
      'OV_audio-poster color-error icon-mic-off'
    );
    audioPoster.setAttribute('data-testid', 'mute_indicator');
    audioPoster.style.display = 'none';

    wrapper.append(videoPoster);
    wrapper.append(audioPoster);

    container.append(wrapper);

    // if the subscriber joined w/o allowing mic, add muted icon
    if (!subject.stream.hasAudio) {
      container?.classList?.add('audio-indicator-off');
      audioPoster.style.display = 'block';
    }

    subject.on('streamPropertyChanged', (event) => {
      videoPoster.style.display = event.stream.videoActive ? 'none' : 'block';
      audioPoster.style.display = event.stream.audioActive ? 'none' : 'block';
    });

    subject.on('videoElementDestroyed', () => {
      wrapper.remove();
    });

    const video = subject.createVideoElement(wrapper);
    video.classList.add('OT_video-element');
  }

  initPublisher(containerSelector, configuration = {}) {
    const publisher = this.OV.initPublisher(undefined, configuration);
    this.injectVideoWidget(
      publisher,
      containerSelector,
      'OV_publisher_wrapper full-width-height'
    );

    // openVidu does not support explicit destruction of publisher; we add this method artifically
    publisher.destroy = () => {};

    return publisher;
  }

  checkScreenShareCapability() {
    return new Promise((resolve) => {
      resolve(false);
    });
  }

  getNativeSession() {
    return this.session;
  }

  getCycleVideoCallback() {
    return new Promise((resolve) => {
      resolve(null);
    });
  }

  connect(token) {
    return this.session.connect(token);
  }

  disconnect() {
    if (this.session) {
      this.session.disconnect();
    }
  }

  subscribe(stream, containerSelector) {
    const subscription = this.session.subscribe(stream, undefined);
    this.injectVideoWidget(
      subscription,
      containerSelector,
      'OT_root OT_subscriber OT_fit-mode-cover'
    );
    return subscription;
  }

  setOnSubscriberConnected(callback) {
    this.onSubscriberConnected = callback;
  }

  setOnSubscriberDisconnected(callback) {
    this.onSubscriberDisconnected = callback;
  }

  renderVideoContainer() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          className="OT_mirrored OT_root OT_publisher OT_fit-mode-cover"
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        >
          <div
            data-testid="publisher"
            id="publisher"
            className="OT_widget-container"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    );
  }
}
