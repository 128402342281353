import React from 'react';
import './VideoControls.scss';
import { OnOffButton } from '../atoms';

const VideoControls = ({ controls }) => {
  const handleToggleVideo = (cameraOn) => {
    if (controls) {
      controls.publishVideo(cameraOn);
    }
  };

  const handleToggleAudio = (audioOn) => {
    if (controls) {
      controls.publishAudio(audioOn);
    }
  };

  const handleCycleCamera = () => {
    if (controls) {
      controls.cycleVideo();
    }
  };

  return (
    controls && (
      <div data-testid="video_controls" className="video-controls">
        {controls.publishVideo && (
          <OnOffButton
            size="big"
            defaultOn
            cssOn="icon-camera-on"
            cssOff="icon-camera-off"
            onClick={handleToggleVideo}
          />
        )}
        {controls.publishAudio && (
          <OnOffButton
            size="big"
            defaultOn
            cssOn="icon-mic-on"
            cssOff="icon-mic-off"
            onClick={handleToggleAudio}
          />
        )}
        {controls.cycleVideo && (
          <OnOffButton
            size="big"
            defaultOn
            cssOn="icon-reverse-camera"
            cssOff="icon-reverse-camera"
            onClick={handleCycleCamera}
          />
        )}
      </div>
    )
  );
};

export default VideoControls;
