import React, { useEffect, useState } from 'react';
import './PreTest.scss';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { Label } from '../atoms';
import { getStateServerUrl } from '../utils';

const getColor = (status) => {
  if (status === null) {
    return ' color-disabled ';
  }

  if (status) {
    return 'color-success';
  }

  return 'color-error';
};

const isTestFinished = (wsPing, audioAvailable, videoAvailable) => {
  return wsPing !== null && audioAvailable !== null && videoAvailable !== null;
};

const getCameraCss = (videoAvailable) => {
  return `icon-camera-on  ${getColor(videoAvailable)}`;
};

const getAudioCss = (audioAvailable) => {
  return `icon-mic-on  ${getColor(audioAvailable)}`;
};

const getSocketsCss = (wsPing) => {
  return `icon-cast-connected  ${getColor(wsPing)}`;
};

const PreTest = ({ onFinish }) => {
  const MAX_RECONNECT_COUNT = 4;
  const [testRunning, setTestRunning] = useState(false);
  const [videoAvailable, setVideoAvailable] = useState(null);
  const [audioAvailable, setAudioAvailable] = useState(null);
  const [wsPing, setWsPing] = useState(null);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const [socket] = useState(
    io(getStateServerUrl(), {
      transports: ['websocket'],
      upgrade: false,
      autoConnect: false,
    })
  );
  const { t } = useTranslation();

  useEffect(() => {
    socket.on('connect', () => {
      setWsPing(true);
    });

    socket.on('connect_failed', () => {
      setWsPing(false);
    });

    socket.on('connect_timeout', () => {
      setWsPing(false);
    });

    socket.on('reconnecting', () => {
      setReconnectAttempt((prev) => {
        const attempt = prev + 1;
        if (attempt > MAX_RECONNECT_COUNT) {
          setWsPing(false);
          socket.destroy();
        }
        return attempt;
      });
    });

    socket.on('error', () => {
      setWsPing(false);
    });

    return () => {
      socket.destroy();
    };
  }, [socket]);

  useEffect(() => {
    if (reconnectAttempt > MAX_RECONNECT_COUNT) {
      setWsPing(false);
      socket.disconnect();
    }
  }, [reconnectAttempt, socket]);

  useEffect(() => {
    if (testRunning && isTestFinished(wsPing, audioAvailable, videoAvailable)) {
      setTestRunning(false);
      socket.close();
      onFinish({ wsPing, audioAvailable, videoAvailable });
    }
  }, [wsPing, audioAvailable, videoAvailable, onFinish, socket, testRunning]);

  const checkMediaSupport = () => {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      setAudioAvailable(false);
      setVideoAvailable(false);
      return Promise.resolve();
    }

    return checkAudioSupport().then(() => checkVideoSupport());
  };

  const checkVideoSupport = () => {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setVideoAvailable(true);
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch(() => {
        setVideoAvailable(false);
      });
  };

  const checkAudioSupport = () => {
    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioAvailable(true);
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch(() => {
        setAudioAvailable(false);
      });
  };

  const handleStartTest = () => {
    setWsPing(null);
    setReconnectAttempt(0);
    setVideoAvailable(null);
    setAudioAvailable(null);
    setTestRunning(true);
    checkMediaSupport().then(() => socket.open());
  };

  return (
    <div className="pre-test panel">
      {testRunning && (
        <div
          data-testid="simple_loader"
          className="simple-loader pre-test-loader"
        />
      )}

      <div className="pre-test-title ">{t('pcTest')}</div>

      <div className="pre-test-description margin-bigger">
        {t('pcDescription.title')}
        <div>{`•  ${t('pcDescription.item1')}`}</div>
        <div>{`•  ${t('pcDescription.item2')}`}</div>
      </div>

      <div data-testid="pre_test_icons" className="row">
        <i
          data-testid="audio"
          className={`rounded-icon ${getAudioCss(audioAvailable)}`}
        />
        <i
          data-testid="camera"
          className={`rounded-icon ${getCameraCss(videoAvailable)}`}
        />
        <i
          data-testid="cast"
          className={`rounded-icon ${getSocketsCss(wsPing)}`}
        />
      </div>

      <button
        type="button"
        className="yr-button margin-bigger"
        data-testid="run_test"
        onClick={handleStartTest}
      >
        <Label keyId="pcRunTest" />
      </button>
    </div>
  );
};

export default PreTest;
