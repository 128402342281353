import React from 'react';
import './ButtonRound.scss';

const ButtonRound = ({ onClick, size, ...props }) => {
  const css = `button-round ${size === 'big' ? 'button-round-big' : ''}`;
  return (
    // eslint-disable-next-line
    <div
      data-testid="button_round"
      className={`${css} ${props.className || ''} `}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};

export { ButtonRound };
