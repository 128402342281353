import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keys, merge, reduce } from 'lodash';
import { NumberInput } from '../../atoms';
import { format } from '../../utils/date-fns';

export const SampleItem = ({ sample, onChange }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ $total: 0, batches: {}, valid: false });
  const [sampleMaxQuantityReached, setSampleMaxQuantityReached] =
    useState(false);
  const [sampleMinQuantityReached, setSampleMinQuantityReached] =
    useState(false);

  useEffect(() => {
    onChange({ [sample.id]: state.batches }, { [sample.id]: state.valid });
  }, [onChange, sample.id, state]);

  const handleOnQuantityChange = (batch, value) => {
    setState((prevState) => {
      const nextState = {
        ...merge(prevState, { batches: { [batch.id]: value } }),
      };
      nextState.$total = reduce(
        keys(nextState.batches),
        (acc, batchId) => acc + nextState.batches[batchId],
        0
      );
      setSampleMaxQuantityReached(
        nextState.$total + 1 > sample.maxTotalQuantity
      );
      setSampleMinQuantityReached(nextState.$total >= sample.minTotalQuantity);
      nextState.valid =
        nextState.$total >= sample.minTotalQuantity &&
        nextState.$total <= sample.maxTotalQuantity;
      return nextState;
    });
  };

  return (
    <div className="sfg-item v-spacer-big">
      <div className="v-spacer-big">
        <div className="row section-header-small bg-color-white">
          <div className="priority">{sample.name}</div>
          <div className="sfg-control">{state.$total}</div>
        </div>
        {!sampleMinQuantityReached && (
          <div className="row row-end color-error font-small">
            {t('sampleCountError', { min: sample.minTotalQuantity })}
          </div>
        )}
      </div>
      {sample.batches.map((batch, index) => {
        const isLast = index === sample.batches.length - 1;
        const clazz = isLast ? 'sfg-item-last' : '';
        return (
          <div key={batch.id} className={`row sfg-item-batch ${clazz}`}>
            <div className="priority sfg-item-details">
              <div>{batch.name}</div>
              {batch.expiryDate && (
                <div>{`${t('expiryDate')}: ${format(
                  new Date(batch.expiryDate),
                  'dd.MM.yyyy'
                )}`}</div>
              )}
            </div>
            <div className="sfg-control">
              <NumberInput
                min={batch.minQuantity}
                max={batch.maxQuantity}
                plusDisabled={sampleMaxQuantityReached}
                onChange={(value) => {
                  handleOnQuantityChange(batch, value);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
