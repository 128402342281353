import { isAfter } from 'date-fns';
import React from 'react';
import './RoomsList.scss';
import '../styles/react-datepicker.scss';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../atoms';
import { format, formatDistanceToNow } from '../utils/date-fns';

const RoomsList = ({ rooms, selectedRoom, onRoomSelected }) => {
  const { t } = useTranslation();

  const formatDates = (startTime) => {
    const jsDate = new Date(startTime);
    const start = format(jsDate, 'PPP');
    const diff = formatDistanceToNow(jsDate, { addSuffix: true });
    const inPast = isAfter(new Date(), jsDate);
    return [start, diff, inPast];
  };

  return (
    rooms && (
      <div data-testid="join_room_list" className="room-list-wrapper">
        <div className="room-list">
          {rooms.map((room) => {
            const [start, diff, inPast] = formatDates(room.startTime);
            return (
              <ListItem
                onClick={() => onRoomSelected(room)}
                selected={selectedRoom && selectedRoom.id === room.id}
                key={room.id}
                id={room.id}
                noTooltip
              >
                <div className="row row-space-between font-bold">
                  <div>{room.name}</div>
                  <div>#{room.code}</div>
                </div>
                <div className="row row-space-between">
                  <div className="room-list-distance">{start}</div>
                  <div
                    className={`room-list-distance ${
                      inPast ? 'color-error' : ''
                    } `}
                  >
                    {diff}
                  </div>
                </div>
              </ListItem>
            );
          })}
          {rooms.length === 0 && <div>{t('noEntries')}</div>}
        </div>
      </div>
    )
  );
};

export default RoomsList;
