import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import uuid from '../../services/UUID';
import { ComChannelContext } from '../../services/ComChannel';
import PointerSvg from './PointerSvg';

const Pointer = ({ isOrganizer, iFrameRef, children }) => {
  const channel = useContext(ComChannelContext);
  const overlay = useRef();
  const [timeoutHandle, setTimeoutHandle] = useState(0);
  const [pointers, setPointers] = useState([]);

  const showPointer = useCallback((pointerEvent) => {
    setPointers((prevState) => {
      // It is important to give out a new array, so React will notice the change (use concat instead of push)
      return prevState.concat([pointerEvent]);
    });
  }, []);

  const broadcastPointer = useCallback(
    (pointerEvent) => {
      channel.broadcastPointerShow(pointerEvent);
    },
    [channel]
  );

  const handlePointerCompletion = (id) => {
    setPointers((prevState) => prevState.filter((it) => it.id !== id));
  };

  const getCoordinates = (event) => {
    if (event.touches || event.changedTouches) {
      const touch = event.touches[0] || event.changedTouches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    return { x: event.clientX, y: event.clientY };
  };

  // --------------------------------------------------------------------------------------------
  // Event dispatching from overlay (PDF)
  // --------------------------------------------------------------------------------------------
  const calculatePointer = (event) => {
    // Map the position of the pointer in screen coords from percentage of the viewport width/height
    const boundingRect = overlay.current.getBoundingClientRect();
    const pointerEvent = {
      id: event.id,
      posX: event.rx * boundingRect.width + boundingRect.left,
      posY: event.ry * boundingRect.height + boundingRect.top,
      isOrganizer: event.isOrganizer,
    };
    return pointerEvent;
  };

  const handleMouseDown = (event) => {
    const { x, y } = getCoordinates(event);
    // Map the position of the pointer in screen coords in percentage of the viewport width/height
    const boundingRect = overlay.current.getBoundingClientRect();
    const mx = x - boundingRect.left;
    const my = y - boundingRect.top;
    const pointerEvent = {
      id: uuid(),
      posX: x,
      posY: y,
      rx: mx / boundingRect.width,
      ry: my / boundingRect.height,
      isOrganizer,
      source: 'overlay',
    };

    // Show pointer after 500ms if the mouse button is not released meanwhile
    setTimeoutHandle(
      setTimeout(() => {
        showPointer(pointerEvent);
        broadcastPointer(pointerEvent);
      }, 500)
    );
  };

  const handleMouseUp = () => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
  };

  const renderPointer = (pointer) => {
    return (
      <PointerSvg
        key={pointer.id}
        posX={pointer.posX}
        posY={pointer.posY}
        isOrganizer={pointer.isOrganizer}
        completionCallback={() => handlePointerCompletion(pointer.id)}
      />
    );
  };

  // --------------------------------------------------------------------------------------------
  // Event dispatching from iFrame (Reveal.js media)
  // --------------------------------------------------------------------------------------------
  useEffect(() => {
    const handleWindowMessage = (event) => {
      try {
        const data =
          event.data &&
          typeof event.data === 'string' &&
          event.data.indexOf('pointer') > 0 &&
          JSON.parse(event.data);
        if (data) {
          const boundingRect = overlay.current.getBoundingClientRect();
          if (data.eventName === 'pointerRequested') {
            const pointerEvent = {
              id: uuid(),
              posX: data.pointerRequest.posX + boundingRect.left,
              posY: data.pointerRequest.posY + boundingRect.top,
              rx: data.pointerRequest.rx,
              ry: data.pointerRequest.ry,
              isOrganizer,
              source: 'iframe',
            };
            showPointer(pointerEvent);
            broadcastPointer(pointerEvent);
          }
          if (data.eventName === 'pointerCalculated') {
            const pointerEvent = {
              id: data.pointerCalculation.id,
              posX: data.pointerCalculation.posX + boundingRect.left,
              posY: data.pointerCalculation.posY + boundingRect.top,
              rx: data.pointerCalculation.rx,
              ry: data.pointerCalculation.ry,
              isOrganizer: data.pointerCalculation.isOrganizer,
              source: 'iframe',
            };
            showPointer(pointerEvent);
          }
        }
      } catch (e) {
        console.error(`handleWindowMessage Error:${JSON.stringify(e)}`);
      }
    };
    window.addEventListener('message', handleWindowMessage);

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [isOrganizer, showPointer, broadcastPointer]);

  // --------------------------------------------------------------------------------------------
  // Event from other client
  // --------------------------------------------------------------------------------------------
  useEffect(() => {
    const handleRemotePointerShown = (event) => {
      if (event.source) {
        if (event.source === 'overlay') {
          const pointerEvent = calculatePointer(event);
          showPointer(pointerEvent);
        }
        if (event.source === 'iframe') {
          const msg = {
            namespace: 'reveal',
            eventName: 'pointer',
            pointer: event,
          };
          iFrameRef.current.contentWindow.postMessage(msg, '*');
        }
      }
    };
    const unsubscribe = channel.onPointerShown(handleRemotePointerShown);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [channel, showPointer, iFrameRef]);

  return (
    <div className="full-width-height" ref={overlay}>
      {/* eslint-disable-next-line */}
      <div
        data-testid="pointer_overlay"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        className="full-width-height"
      >
        {pointers.map(renderPointer)}
        {children}
      </div>
    </div>
  );
};

export { Pointer };
