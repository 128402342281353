import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ProcessLauncher.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Store from '../services/Store';
import SideBarMenu from '../components/SideBarMenu';
import SearchableList from '../components/SearchableList';
import { SampleWizard } from '../components/Samples';
import { apiClient } from '../services/ApiClient';
import FormViewer from '../components/FormViewer/FormViewer';
import featureEnabled, { features } from '../services/FeatureFlag';

const ProcessLauncher = ({
  room,
  channel,
  currentSubscriberCount,
  onMediaSelected,
  onShareScreen,
  closeStage,
  saveMediaTracking,
}) => {
  const { t } = useTranslation();
  const attendee = room.attendees[0];
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [showFormLibrary, setShowFormLibrary] = useState(false);
  const [showSamples, setShowSamples] = useState(false);
  const [formData, setFormData] = useState(null);
  const sidebar = useRef(null);
  const container = useRef(null);

  // const handleOnMediaMenuItemClick = (e) => {
  //     alert(e.option.label + " > " + e.value.name + " > Clicked!");
  // }

  // const mediaMenuItems = [
  //     {id: '1', label: 'Menu item Option1', onClick: handleOnMediaMenuItemClick},
  //     {id: '2', label: 'Menu item Option2', onClick: handleOnMediaMenuItemClick},
  //     {id: '3', label: 'Menu item Option3', onClick: handleOnMediaMenuItemClick},
  // ]

  const unselectListItems = (list) => {
    list.forEach((it) => {
      // eslint-disable-next-line
      it.$selected = false;
    });
  };

  // Create an entry in the Store for mediaTracking
  const startMediaTracking = useCallback(
    (mediaId) => {
      // Only start tracking if the feature flag is enable and there is at least one attendee planned for the room
      if (featureEnabled(features.MEDIA_TIME_TRACKING) && attendee?.id) {
        // Use the Store to hold the mediaTracking data with startDate for each sequence
        Store.mediaTracking = {
          id: mediaId,
          roomId: room.id,
          attendeeId: attendee.id,
          startTime: new Date().toISOString(),
          trackingInfo: [
            {
              sequence: 0,
              page: 1,
              startTime: new Date(),
            },
          ],
        };
      }
    },
    [attendee?.id, room.id]
  );

  const handleMediaClick = (mediaItem) => {
    if (selectedForm) {
      unselectListItems(room.forms || []);
      setSelectedForm(null);
    }

    setShowMediaLibrary(false);

    const media = room.media || [];
    unselectListItems(media);
    // eslint-disable-next-line
    mediaItem.$selected = true;
    setSelectedMedia(mediaItem);

    const sessionMedia = Store.sessionMedia || [];
    if (!sessionMedia.includes(mediaItem.id)) {
      sessionMedia.push(mediaItem.id);
    }
    Store.sessionMedia = sessionMedia;

    // When a media item is clicked we start to check if we can track the media time
    startMediaTracking(mediaItem.id);

    onMediaSelected(mediaItem);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', saveMediaTracking);
  }, [saveMediaTracking]);

  const handleFormClick = (formItem) => {
    if (selectedMedia) {
      unselectListItems(room.media || []);
      setSelectedMedia(null);
    }

    setShowFormLibrary(false);

    const forms = room.forms || [];
    unselectListItems(forms);
    // eslint-disable-next-line
    formItem.$selected = true;
    setSelectedForm(formItem);
    apiClient
      .personalizeForm(room.id, formItem.id, attendee.id)
      .then((result) => {
        const enrichedFormData = {
          html: result.data.personalizeForm,
          ...formItem,
        };
        setFormData(enrichedFormData);
        channel.broadcastOrganizerFormOpen(enrichedFormData);
      });
  };

  const getMedia = () => {
    return room ? room.media || [] : [];
  };

  const getForms = () => {
    return room ? room.forms || [] : [];
  };

  const handleShowMediaLibrary = () => {
    closeAll();
    setShowMediaLibrary(!showMediaLibrary);
  };

  const handleShowFormLibrary = () => {
    closeAll();
    if (!attendee) {
      toast(t('formsValidationKnownAttendee'), {
        type: toast.TYPE.SUCCESS,
      });
      return;
    }
    setShowFormLibrary(!showFormLibrary);
  };

  const handleScreenShareClick = () => {
    onShareScreen();
  };

  const handleOnSampleDrop = () => {
    closeAll();
    setShowSamples(!showSamples);
  };

  const closeAll = useCallback(() => {
    saveMediaTracking();
    setShowMediaLibrary(false);
    setShowFormLibrary(false);
    setShowSamples(false);
    setFormData(null);
  }, [saveMediaTracking]);

  useEffect(() => {
    if (closeStage) {
      closeAll();
    }
  }, [closeAll, closeStage]);

  const samplesFormEnabled = () =>
    !!(room.attendees && room.attendees.length === 1) &&
    featureEnabled(features.SAMPLES);

  const handleOnAttendeeHasSubmittedForm = (formSubmitData, formHtml) => {
    return apiClient
      .storeFormInstance(
        room.id,
        formData.id,
        attendee.id,
        formSubmitData,
        formHtml
      )
      .then((result) => {
        toast.success(t('formSubmitted'));
        return apiClient.transferFormInstance(result.data.storeFormInstance.id);
      })
      .then(() => {
        toast.success(t('formInstanceTransferred'));
      });
  };

  useEffect(() => {
    const listener = (event) => {
      if (
        !sidebar.current ||
        sidebar.current.contains(event.target) ||
        !container.current ||
        container.current.contains(event.target)
      ) {
        return;
      }
      closeAll();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [closeAll]);

  return (
    <div className="processLauncher">
      <SideBarMenu
        ref={sidebar}
        currentSubscriberCount={currentSubscriberCount}
        onShowMediaLibrary={handleShowMediaLibrary}
        onShowFormLibrary={handleShowFormLibrary}
        onScreenShare={onShareScreen ? handleScreenShareClick : null}
        onSampleDrop={samplesFormEnabled() && handleOnSampleDrop}
      />

      {showMediaLibrary && (
        <SearchableList
          ref={container}
          className="card vw-25"
          label="mediaLibrary"
          // TODO: Re-enable when new attendee management is mature, currently prevents upgrade to react 17
          // menuItems={featureEnabled(features.NEW_ATTENDEE_MGMT) && mediaMenuItems}
          items={getMedia()}
          onItemSelected={(media) => handleMediaClick(media)}
          onCloseItem={() => setShowMediaLibrary(false)}
        />
      )}

      {showFormLibrary && (
        <SearchableList
          ref={container}
          className="card vw-25"
          label="formLibrary"
          items={getForms()}
          onItemSelected={(form) => handleFormClick(form)}
          onCloseItem={() => setShowFormLibrary(false)}
        />
      )}

      {formData && (
        <div className="card">
          <FormViewer
            room={room}
            attendeeId={attendee.id}
            formData={formData}
            onAttendeeHasSubmittedForm={handleOnAttendeeHasSubmittedForm}
            onClose={() => closeAll()}
          />
        </div>
      )}

      {showSamples && (
        <SampleWizard
          ref={container}
          room={room}
          attendees={room.attendees}
          onCancel={handleOnSampleDrop}
        />
      )}
    </div>
  );
};

export default ProcessLauncher;
