import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AsyncButton, Label } from '../atoms';
import { apiClient } from '../services/ApiClient';
import Store from '../services/Store';
import { ComChannelContext } from '../services/ComChannel';
import SessionEventType from '../services/SessionEventType';

const Logout = ({ roomData }) => {
  const { t } = useTranslation();
  const channel = useContext(ComChannelContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const handleCompleteRoom = () => {
    setButtonsDisabled(true);
    return apiClient
      .completeRoomMutation(
        roomData.id,
        JSON.stringify(Store.sessionMedia || []),
        Store.sessionNotes || ''
      )
      .then(() => apiClient.transferRoomSession(roomData.id))
      .then(() => {
        toast(t('sessionTransferred'), {
          type: toast.TYPE.SUCCESS,
        });
        generateSessionEvent(SessionEventType.ROOM_CLOSED);
        Store.sessionMedia = null;
        handleContinueLater();
      })
      .finally(() => setButtonsDisabled(false));
  };

  const handleCancelRoom = () => {
    setButtonsDisabled(true);
    return apiClient
      .cancelRoomMutation(roomData.id)
      .then(() => {
        generateSessionEvent(SessionEventType.ROOM_CANCELLED);
        Store.sessionMedia = null;
        handleContinueLater();
      })
      .finally(() => setButtonsDisabled(false));
  };

  const handleContinueLater = () => {
    channel.broadcastOrganizerLogOut();
    apiClient.logOut();
    generateSessionEvent(SessionEventType.ORGANIZER_LEFT);

    toast(t('sessionEnded'), {
      type: toast.TYPE.SUCCESS,
    });

    window.location.href = '/#/lobby';
  };

  const generateSessionEvent = (type) => {
    const eventData = {
      attendees: roomData.attendees
        ? roomData.attendees.map((it) => it.id)
        : [],
      relatedSessionId: channel.roomInfo
        ? channel.roomInfo.sessionId
        : undefined,
    };
    return apiClient.addSessionEvent(roomData.id, type, eventData);
  };

  return (
    <section className="column h100">
      <b>
        <Label keyId="logout" />
      </b>
      <div>
        <Label keyId="logoutDescription" />
      </div>
      <br />
      <div className="column column-center column-space-around">
        <AsyncButton onClick={handleContinueLater} disabled={buttonsDisabled}>
          <Label keyId="roomContinueLater" />
        </AsyncButton>
        <AsyncButton
          className="button-error"
          onClick={handleCancelRoom}
          disabled={buttonsDisabled}
        >
          <Label keyId="roomCancel" />
        </AsyncButton>
        <AsyncButton
          className="button-success"
          onClick={handleCompleteRoom}
          disabled={buttonsDisabled}
        >
          <Label keyId="roomComplete" />
        </AsyncButton>
      </div>
    </section>
  );
};

export default Logout;
