import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './styles/style.scss';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import NotAuthenticated from './components/NotAuthenticated';
import Authenticate from './containers/Authenticate';
import AppAttendee from './containers/AppAttendee';
import AttendeeAuthenticate from './containers/AttendeeAuthenticate';
import AppOrganizerRoom from './containers/AppOrganizerRoom';
import AppOrganizerLobby from './containers/AppOrganizerLobby';
import { initI18n } from './services/i18n';
import featureEnabled, { features } from './services/FeatureFlag';
import { Feedback } from './containers/Feedback';

const sentryDsn = `${process.env.REACT_APP_SENTRY_DSN}`;
const sentryEnv = `${process.env.REACT_APP_SENTRY_ENV}`;
const version = `${process.env.REACT_APP_VERSION}`;
const enableSentry =
  sentryDsn &&
  sentryDsn.length > 0 &&
  sentryDsn !== 'undefined' &&
  sentryEnv &&
  sentryEnv.length > 0 &&
  sentryEnv !== 'undefined';
if (enableSentry) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: version,
    environment: sentryEnv,
  });
}

const CloseButton = () => {
  return <div>&times;</div>;
};

const Routes = () => {
  initI18n();

  return (
    <Router>
      <Switch>
        <Route
          path="/initSession"
          render={(routeProps) => (
            <Authenticate {...routeProps}>
              <AppOrganizerLobby {...routeProps} />
            </Authenticate>
          )}
        />

        <Route
          path="/lobby"
          render={(routeProps) => (
            <Authenticate {...routeProps}>
              <AppOrganizerLobby {...routeProps} />
            </Authenticate>
          )}
        />

        <Route
          path="/room/:id"
          render={(routeProps) => (
            <Authenticate {...routeProps}>
              <AppOrganizerRoom {...routeProps} />
            </Authenticate>
          )}
        />

        <Route
          path={
            featureEnabled(features.NEW_ATTENDEE_MGMT)
              ? '/join/:id/name/:name/email/:email'
              : '/join/:id'
          }
          render={(routeProps) => (
            <AttendeeAuthenticate
              roomId={routeProps.match.params.id}
              history={routeProps.history}
            >
              <AppAttendee
                roomId={routeProps.match.params.id}
                userName={routeProps.match.params.name}
                userEmail={routeProps.match.params.email}
                history={routeProps.history}
              />
            </AttendeeAuthenticate>
          )}
        />

        <Route path="/feedback">
          <Feedback />
        </Route>

        <Route component={NotAuthenticated} />
      </Switch>
      <ToastContainer
        toastClassName="toast-custom"
        hideProgressBar
        closeButton={<CloseButton />}
        pauseOnHover={false}
        autoClose={15000}
        icon={false}
        theme="colored"
      />
    </Router>
  );
};

ReactDOM.render(<Routes />, document.getElementById('root'));
