import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { ComChannelContext } from '../services/ComChannel';
import { initializeVideoControls } from '../services/VideoSession';
import './Video.scss';
import VideoControls from './VideoControls';
import logoWhite from '../images/y-logo-white.png';

import { layout } from '../utils';

const LayoutContainer = (container, opts) => {
  return { layout: () => layout(container, opts) };
};

const Video = ({
  session,
  focused,
  streamName,
  screenShareEnabled,
  isOrganizer,
}) => {
  const layoutRef = useRef();
  const layoutInit = useRef();
  const { t } = useRef(useTranslation()).current;
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [cameraControls, setCameraControls] = useState(null);
  const focusedCss = !focused ? 'subscribers-in-row' : '';

  const channel = useContext(ComChannelContext);

  useEffect(() => {
    layoutInit.current = LayoutContainer(layoutRef.current, { animate: true });
  }, []);

  useEffect(() => {
    if (layoutInit.current) {
      const debounced = debounce(layoutInit.current.layout, 300);
      layoutInit.current.layout();

      let resizeTimeout;
      window.onresize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          debounced();
        }, 300);
      };
    }
  }, [subscribersCount, focused]);

  useEffect(() => {
    const onSubscriberConnected = () => {
      setSubscribersCount((prev) => prev + 1);
    };

    const onSubscriberDisconnected = () => {
      setSubscribersCount((prev) => prev - 1);
    };

    const sessionInfo = channel.getRoomInfo();

    const { destructor, camera } = initializeVideoControls(
      channel,
      streamName,
      sessionInfo.token,
      screenShareEnabled,
      onSubscriberConnected,
      onSubscriberDisconnected,
      t,
      session,
      isOrganizer
    );
    camera.then((controls) => setCameraControls(controls));

    return destructor;
  }, [session, channel, streamName, t, isOrganizer, screenShareEnabled]);

  const getSubscribersClass = () => {
    return `video-subscribers-container ${
      subscribersCount === 1 && focused ? 'single-subscriber' : ''
    } ${focusedCss}`;
  };

  const getPublisherContainerCss = () => {
    const subsCss = subscribersCount > 0 || !focused ? '' : 'size-full';
    return `video-publisher-container ${subsCss}`;
  };

  return (
    <div data-testid="video" className={`video-thumb ${focusedCss}`}>
      <div className="video-header">
        <img src={logoWhite} className="video-header-img" alt="" />
      </div>

      <div className={getPublisherContainerCss()}>
        <div className="video-publisher">{session.renderVideoContainer()}</div>
        <VideoControls controls={cameraControls} />
      </div>

      <div
        data-testid="subscribers"
        id="subscribers"
        className={getSubscribersClass()}
        ref={layoutRef}
      />

      <ReactTooltip />
    </div>
  );
};

export default Video;
