import React from 'react';
import ReactTooltip from 'react-tooltip';
import { AsyncButton, Label } from '../../atoms';

const FormViewerOrganizerToolbar = ({ onClose }) => {
  return (
    onClose && (
      <div className="form-viewer-toolbar">
        <AsyncButton id="submit_button" onClick={onClose}>
          <Label keyId="close" />
        </AsyncButton>
        <ReactTooltip />
      </div>
    )
  );
};

export default FormViewerOrganizerToolbar;
