import React, { useState } from 'react';
import './NotAuthenticated.scss';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import callImg from '../images/video-call.png';
import { Alert, Label } from '../atoms';
import apiRest from '../services/ApiRestClient';
import useRemoveLoader from './useRemoveLoader';
import PreTest from './PreTest';
import featureEnabled, { features } from '../services/FeatureFlag';

const isNewAttendeeMgmtAvailable = featureEnabled(features.NEW_ATTENDEE_MGMT);

const Welcome = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const labelError = queryString.parse(props.location.search).e;
  const roomCodeFromParam = queryString.parse(props.location.search).roomCode;
  const nameFromParam = queryString.parse(props.location.search).name;
  const emailFromParam = queryString.parse(props.location.search).email;

  const [code, setCode] = useState(roomCodeFromParam);
  const [userName, setUserName] = useState(nameFromParam);
  const [userEmail, setUserEmail] = useState(emailFromParam);
  const [useAnimation, setUseAnimation] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const [testPassed, setTestPassed] = useState(false);
  const [videoAvailable, setVideoAvailable] = useState(null);
  const [audioAvailable, setAudioAvailable] = useState(null);
  const [wsPing, setWsPing] = useState(null);
  const [translatedError, setTranslatedError] = useState(
    labelError && i18n.exists(labelError) && t(labelError)
  );
  const [showAlternateLogin, setShowAlternateLogin] = useState(false);

  useRemoveLoader();

  const handleJoin = (event) => {
    event.preventDefault();
    setInvalidCode(!code);
    setInvalidName(!userName);
    setInvalidEmail(!userEmail);

    apiRest.getRoom(code).then((room) => {
      const roomCode = room && room.id;
      if (roomCode) {
        setRoomId(roomCode);
      } else {
        setInvalidCode(true);
      }
      if (
        invalidCode ||
        (isNewAttendeeMgmtAvailable && (invalidName || invalidEmail))
      ) {
        setRoomId(null);
        setUseAnimation(true);
        setTimeout(() => {
          setUseAnimation(false);
        }, 1500);
      }
    });
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const getInputCodeClass = () => {
    return invalidCode && useAnimation
      ? 'welcome-login-code animation-shake error-border'
      : 'welcome-login-code';
  };

  const getInputNameClass = () => {
    return invalidName && useAnimation
      ? 'welcome-login-name-email animation-shake error-border'
      : 'welcome-login-name-email';
  };

  const getInputEmailClass = () => {
    return invalidEmail && useAnimation
      ? 'welcome-login-name-email animation-shake error-border'
      : 'welcome-login-name-email';
  };

  const handleOnPreTestFinish = (event) => {
    setTestPassed(event.wsPing);
    setAudioAvailable(event.audioAvailable);
    setVideoAvailable(event.videoAvailable);
    setWsPing(event.wsPing);
    setTranslatedError(undefined);
  };

  const handleStartRetest = () => {
    setTestPassed(false);
  };

  const getColor = (status) => {
    if (status === null) {
      return 'color-disabled';
    }

    if (status) {
      return 'color-success';
    }

    return 'color-error';
  };

  return (
    <div className="welcome">
      {/* Redirect to client url upon success */}
      {isNewAttendeeMgmtAvailable && roomId && userName && userEmail && (
        <Redirect to={`/join/${roomId}/name/${userName}/email/${userEmail}`} />
      )}
      {!isNewAttendeeMgmtAvailable && roomId && (
        <Redirect to={`/join/${roomId}`} />
      )}

      {showAlternateLogin && (
        <Alert onClose={() => setShowAlternateLogin(false)}>
          <div>{t('alternateLogin')}</div>
          <div>
            <Link className="yr-button" to="/lobby/?idpHint=ysuraHub">
              {t('ysuraLogin')}
            </Link>
            &nbsp;
            <Link className="yr-button" to="/lobby/?idpHint=local">
              {t('localLogin')}
            </Link>
          </div>
        </Alert>
      )}

      <div className="welcome-header">
        <i className="icon-logo" />
        <div className="welcome-header-middle">
          <img className="welcome-img" src={callImg} alt="" />
        </div>
        <div className="row row-align-center">
          <div className="h-spacer-big">
            <Link
              data-testid="init_session"
              className="yr-button no-hover yr-button--solid-primary h-spacer-big"
              to="/lobby/"
            >
              {t('organisatorLogin')}
            </Link>
          </div>
          {/* eslint-disable-next-line */}
          <i
            onClick={() => setShowAlternateLogin(true)}
            data-testid="login_options"
            className="icon-more-vertical color-white"
          />
        </div>
      </div>

      <div className="welcome-main">
        <div className="welcome-main-header">{t('welcome')}</div>
        {translatedError && (
          <div className="error-box">
            {' '}
            {translatedError} <br />
          </div>
        )}
        <div className="welcome-main-body">
          <div className="row">
            {testPassed && (
              <div className="welcome-join panel">
                <div className="welcome-main-body-title">{t('joinCall')}</div>
                <div className="column-center">
                  <div className="welcome-join-info">
                    <Label keyId="joinInfo" />
                  </div>

                  <div className="column-vh-center">
                    <input
                      type="text"
                      className={getInputCodeClass()}
                      disabled={!testPassed}
                      data-testid="login_code"
                      defaultValue={code}
                      // eslint-disable-next-line
                      autoFocus
                      onChange={handleCodeChange}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          handleJoin(event);
                        }
                      }}
                    />
                    {isNewAttendeeMgmtAvailable && (
                      <>
                        <Label keyId="joinNameInfo" />
                        <input
                          type="text"
                          className={getInputNameClass()}
                          data-testid="user_name"
                          defaultValue={userName}
                          onChange={handleNameChange}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handleJoin(event);
                            }
                          }}
                        />
                        <Label keyId="joinEmailInfo" />
                        <input
                          type="text"
                          className={getInputEmailClass()}
                          data-testid="user_email"
                          defaultValue={userEmail}
                          onChange={handleEmailChange}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handleJoin(event);
                            }
                          }}
                        />
                      </>
                    )}
                    <div className="v-spacer-big" />
                    <div className="row">
                      <button
                        disabled={!testPassed}
                        className="yr-button"
                        data-testid="join"
                        onClick={handleJoin}
                        type="button"
                      >
                        <Label keyId="join" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!testPassed && (
              <div>
                <PreTest onFinish={handleOnPreTestFinish} />
              </div>
            )}
          </div>
          {testPassed && (
            <div className="column-vh-center">
              <div data-testid="test_result" className="row margin-bigger">
                <i
                  data-testid="audio"
                  className={`rounded-icon icon-mic-on ${getColor(
                    audioAvailable
                  )}`}
                />
                <i
                  data-testid="camera"
                  className={`rounded-icon icon-camera-on ${getColor(
                    videoAvailable
                  )}`}
                />
                <i
                  data-testid="cast"
                  className={`rounded-icon icon-cast-connected ${getColor(
                    wsPing
                  )}`}
                />
              </div>
              <button
                type="button"
                data-testid="run_retest"
                className={`yr-button-plain yr-button-plain--big ${getColor(
                  audioAvailable && videoAvailable && wsPing
                )}`}
                onClick={handleStartRetest}
              >
                <Label keyId="pcRunRetest" />
              </button>
            </div>
          )}
        </div>
        <div className="column-vh-center margin-bigger">
          <span>ysura GmbH &#8226; Metzstr. 14b &#8226; 81667 München</span>
          <a href="https://www.ysura.com/">www.ysura.com</a>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
