import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../ListItem/ListItem';

const SearchBox = ({ items, menuItems, searchFunction, clearFunction }) => {
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchFunction) {
      searchFunction(search, offset);
    }
    // eslint-disable-next-line
  }, [search, offset]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    // short text search takes too long and restore result when clean text search
    if (searchTerm.length > 2 || searchTerm.length === 0) {
      setSearch(searchTerm);
      setOffset(0);
      if (clearFunction) {
        clearFunction();
      }
    }
  };

  const handleOnScroll = (e) => {
    const { target } = e;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setOffset((pre) => pre + 50);
    }
  };

  const isItemSelected = (item) => {
    return item.$selected;
  };

  return (
    <>
      <div className="v-spacer">
        <form action="">
          <input
            type="text"
            placeholder={t('search')}
            onChange={handleSearch}
          />
        </form>
      </div>

      <div
        className="overflow-y-auto fix-margin-shadow"
        style={{ position: 'relative' }}
        onScroll={handleOnScroll}
      >
        {items.map((it) => {
          return (
            <ListItem
              key={it.id}
              id={it.id}
              title={it.name}
              selected={isItemSelected(it)}
              description={it.description}
              noTooltip={true}
              icon="icon-person"
              menuItems={menuItems}
            />
          );
        })}
      </div>
    </>
  );
};

export { SearchBox };
