import React from 'react';
import './ListItem.scss';
import ReactTooltip from 'react-tooltip';
// import {ContextMenu} from ".."
import { useTranslation } from 'react-i18next';

const ListItem = ({
  id,
  title,
  description,
  selected,
  onClick,
  thumbnailUrl,
  noTooltip,
  children,
  // #TODO: re-enable when new attendee management is mature
  // menuItems, value,
  icon,
  isMute,
  isDownload,
  isConnected,
  isPending,
}) => {
  const { t } = useTranslation();

  const defaultContent = () => {
    return (
      <>
        <div data-testid="list_item_title" className="list-item-title">
          <div className="list-item-title-text">
            {icon && <i className={`${icon} h-spacer`} />}
            <span>{title}</span>
          </div>
          <div className="list-item-title-icons">
            {/* <ContextMenu menuItems={menuItems} value={value} valueId={id}/> */}
            {isMute != null && (
              <div>
                {!isMute && <i className="icon-mic-item-on mic-on" />}
                {isMute && <i className="icon-mic-item-off mic-off" />}
              </div>
            )}
            {isDownload != null && (
              <div className="h-spacer">
                {!isDownload && <i className="icon-download download" />}
              </div>
            )}
            {isConnected != null && (
              <div className="h-spacer">
                {isConnected && <i className="icon-signal-good signal" />}
                {!isConnected && <i className="icon-signal-low signal" />}
              </div>
            )}
            {isPending && (
              <div className="status">
                <span>{t('pending')}</span>
              </div>
            )}
          </div>
        </div>

        <div
          data-testid="list_item_description"
          className="list-item-description"
        >
          {description || <span> &nbsp; </span>}
        </div>
      </>
    );
  };

  return (
    // eslint-disable-next-line
    <div
      data-testid="list_item"
      className="list-item list-item-with-shadow"
      key={id}
      data-tip
      data-for={id}
      onClick={onClick}
    >
      {selected && <div className="list-item-selected" />}

      {children || defaultContent(title, description)}

      {!noTooltip && (
        <ReactTooltip
          id={id}
          place="right"
          effect="solid"
          delayShow={300}
          className="list-item-tooltip-theme"
        >
          <img
            data-testid="list_item_tooltip_img"
            src={thumbnailUrl}
            alt=""
            className="list-item-thumbnail"
          />
          <div data-testid="list_item_tooltip_title" className="v-spacer">
            {title}
          </div>
          <div data-testid="list_item_tooltip_description">{description}</div>
        </ReactTooltip>
      )}
    </div>
  );
};

export { ListItem };
