const getBestDimensions = (
  minRatio,
  maxRatio,
  Width,
  Height,
  count,
  maxWidth,
  maxHeight
) => {
  let maxArea;
  let targetCols;
  let targetRows;
  let targetHeight;
  let targetWidth;
  let tWidth;
  let tHeight;
  let tRatio;

  for (let i = 1; i <= count; i += 1) {
    const cols = i;
    const rows = Math.ceil(count / cols);

    tHeight = Math.floor(Height / rows);
    tWidth = Math.floor(Width / cols);

    tRatio = tHeight / tWidth;
    if (tRatio > maxRatio) {
      tRatio = maxRatio;
      tHeight = tWidth * tRatio;
    } else if (tRatio < minRatio) {
      tRatio = minRatio;
      tWidth = tHeight / tRatio;
    }

    tWidth = Math.min(maxWidth, tWidth);
    tHeight = Math.min(maxHeight, tHeight);

    const area = tWidth * tHeight * count;

    if (maxArea === undefined || area >= maxArea) {
      maxArea = area;
      targetHeight = tHeight;
      targetWidth = tWidth;
      targetCols = cols;
      targetRows = rows;
    }
  }
  return {
    maxArea,
    targetCols,
    targetRows,
    targetHeight,
    targetWidth,
    ratio: targetHeight / targetWidth,
  };
};

const getLayout = (opts, elements) => {
  const {
    maxRatio,
    minRatio,
    fixedRatio,
    containerWidth,
    containerHeight,
    offsetLeft = 0,
    offsetTop = 0,
    alignItems = 'center',
    maxWidth = Infinity,
    maxHeight = Infinity,
  } = opts;
  const ratios = elements.map((element) => element.height / element.width);
  const count = ratios.length;

  let dimensions;

  if (!fixedRatio) {
    dimensions = getBestDimensions(
      minRatio,
      maxRatio,
      containerWidth,
      containerHeight,
      count,
      maxWidth,
      maxHeight
    );
  } else {
    const ratio = ratios.length > 0 ? ratios[0] : null;
    dimensions = getBestDimensions(
      ratio,
      ratio,
      containerWidth,
      containerHeight,
      count,
      maxWidth,
      maxHeight
    );
  }

  let x = 0;
  let y = 0;
  const rows = [];
  let row;
  const boxes = [];

  for (let i = 0; i < ratios.length; i += 1) {
    if (i % dimensions.targetCols === 0) {
      // This is a new row
      row = {
        ratios: [],
        width: 0,
        height: 0,
      };
      rows.push(row);
    }
    const ratio = ratios[i];
    row.ratios.push(ratio);
    let { targetWidth } = dimensions;
    const { targetHeight } = dimensions;
    if (fixedRatio) {
      targetWidth = targetHeight / ratio;
    }
    row.width += targetWidth;
    row.height = targetHeight;
  }
  // Calculate total row height /  adjustments
  let totalRowHeight = 0;
  let remainingShortRows = 0;
  for (let i = 0; i < rows.length; i += 1) {
    row = rows[i];
    if (row.width > containerWidth) {
      row.height = Math.floor(row.height * (containerWidth / row.width));
      row.width = containerWidth;
    } else if (row.width < containerWidth && row.height < maxHeight) {
      remainingShortRows += 1;
    }
    totalRowHeight += row.height;
  }
  if (totalRowHeight < containerHeight && remainingShortRows > 0) {
    // We can grow some of the rows
    let remainingHeightDiff = containerHeight - totalRowHeight;
    totalRowHeight = 0;
    for (let i = 0; i < rows.length; i += 1) {
      row = rows[i];
      if (row.width < containerWidth) {
        // Evenly distribute the extra height between the rows
        let extraHeight = remainingHeightDiff / remainingShortRows;
        if (
          extraHeight / row.height >
          (containerWidth - row.width) / row.width
        ) {
          // We can't go that big or we'll go too wide
          extraHeight = Math.floor(
            ((containerWidth - row.width) / row.width) * row.height
          );
        }
        row.width += Math.floor((extraHeight / row.height) * row.width);
        row.height += extraHeight;
        remainingHeightDiff -= extraHeight;
        remainingShortRows -= 1;
      }
      totalRowHeight += row.height;
    }
  }
  switch (alignItems) {
    case 'start':
      y = 0;
      break;
    case 'end':
      y = containerHeight - totalRowHeight;
      break;
    case 'center':
    default:
      y = (containerHeight - totalRowHeight) / 2;
      break;
  }

  for (let i = 0; i < rows.length; i += 1) {
    row = rows[i];
    let rowMarginLeft;
    switch (alignItems) {
      case 'start':
        rowMarginLeft = 0;
        break;
      case 'end':
        rowMarginLeft = containerWidth - row.width;
        break;
      case 'center':
      default:
        rowMarginLeft = (containerWidth - row.width) / 2;
        break;
    }
    x = rowMarginLeft;
    let targetHeight = 0;
    for (let j = 0; j < row.ratios.length; j += 1) {
      const ratio = row.ratios[j];

      let { targetWidth } = dimensions;
      targetHeight = row.height;

      if (fixedRatio) {
        targetWidth = Math.floor(targetHeight / ratio);
      } else if (
        targetHeight / targetWidth !==
        dimensions.targetHeight / dimensions.targetWidth
      ) {
        targetWidth = Math.floor(
          (dimensions.targetWidth / dimensions.targetHeight) * targetHeight
        );
      }

      boxes.push({
        left: x + offsetLeft,
        top: y + offsetTop,
        width: targetWidth,
        height: targetHeight,
      });
      x += targetWidth;
    }
    y += targetHeight;
  }
  return boxes;
};

const layoutHelper = (opts, elements) => {
  const {
    maxRatio = 3 / 2,
    minRatio = 9 / 16,
    fixedRatio = true,
    containerWidth = 640,
    containerHeight = 480,
    alignItems = 'center',
    maxWidth = Infinity,
    maxHeight = Infinity,
  } = opts;

  const offsetLeft = 0;
  const offsetTop = 0;

  const smallOnes = elements.filter((element) => !element.big);
  const smallBoxes = getLayout(
    {
      containerWidth: containerWidth - offsetLeft,
      containerHeight: containerHeight - offsetTop,
      offsetLeft,
      offsetTop,
      fixedRatio,
      minRatio,
      maxRatio,
      alignItems,
      maxWidth,
      maxHeight,
    },
    smallOnes
  );

  const boxes = [];
  let smallBoxesIdx = 0;
  elements.forEach((element, idx) => {
    boxes[idx] = smallBoxes[smallBoxesIdx];
    smallBoxesIdx += 1;
  });
  return boxes;
};

export default layoutHelper;
