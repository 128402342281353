import {
  format as fm,
  formatDistanceToNow as fmDTN,
  formatDuration as fmD,
} from 'date-fns';
import { de, enGB } from 'date-fns/locale';

const locales = {
  de,
  en: enGB,
};

let locale = enGB;

export function changeLocale(i18nextLanguage) {
  locale = locales[i18nextLanguage];
}

export function format(date, formatString, options) {
  return fm(date, formatString, {
    locale,
    ...options,
  });
}

export function formatDistanceToNow(date, options) {
  return fmDTN(date, {
    locale,
    ...options,
  });
}

export function formatDuration(duration, options) {
  return fmD(duration, { locale, ...options });
}

export { de, enGB };
