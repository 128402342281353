import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AsyncButton, Label } from '../../atoms';
import { ComChannelContext } from '../../services/ComChannel';

const FormViewerAttendeeToolbar = ({ inputData }) => {
  const { t } = useTranslation();

  const channel = useContext(ComChannelContext);

  const handleFormCancel = () => {
    channel.broadcastAttendeeFormCancel();
  };

  const handleFormSubmit = () => {
    if (inputData.valid) {
      channel.broadcastAttendeeFormSubmit(inputData);
      toast(t('formSubmitted'), {
        type: toast.TYPE.SUCCESS,
      });
    } else {
      toast(t('formNotValid'), {
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <div className="form-viewer-toolbar">
      <AsyncButton
        id="cancel_button"
        onClick={handleFormCancel}
        className="button-no-hover"
      >
        <Label keyId="formAttendeeCancel" />
      </AsyncButton>
      &nbsp;
      <AsyncButton
        id="submit_button"
        onClick={handleFormSubmit}
        className="button-active"
      >
        <Label keyId="formAttendeeSubmit" />
      </AsyncButton>
      <ReactTooltip />
    </div>
  );
};

export default FormViewerAttendeeToolbar;
