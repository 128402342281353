import { SessionOT } from './SessionOT';
import { SessionOV } from './SessionOV';

const providerSessions = {
  TOKBOX: (config) => new SessionOT(config.apiKey, config.sessionId),
  OPENVIDU: () => new SessionOV(),
};

let providerSession;

// config = {apiKey, token, sessionId, rtcProvider}
export const initSession = (config) => {
  if (providerSession) {
    return providerSession;
  }
  return Promise.resolve(providerSessions[config.rtcProvider](config));
};
