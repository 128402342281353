import React, { useState } from 'react';
import './Select.scss';

const Select = ({ options, selectedValue, onChange }) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(selectedValue);

  const handleClick = (option) => {
    setCurrentValue(() => {
      return option.value;
    });
    handleInputClick();
    if (onChange) {
      onChange(option.value);
    }
  };

  const getCurrentLabel = () => {
    return options.find((it) => it.value === currentValue).label;
  };

  const handleInputClick = () => {
    setOpen((previousValue) => !previousValue);
  };

  return (
    <div data-testid="dropdown" className="select">
      {/* eslint-disable-next-line */}
      <div
        data-testid="dropdown_input"
        className="select-input"
        onClick={handleInputClick}
      >
        <div>{getCurrentLabel()}</div>
        <div>
          <i className="icon-up-down f-small" />
        </div>
      </div>

      {open && (
        <div data-testid="dropdown_values" className="select-values">
          {options.map((it) => {
            return (
              // eslint-disable-next-line
              <div
                key={it.value}
                className="select-value"
                onClick={() => handleClick(it)}
              >
                {it.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export { Select };
