import React from 'react';
import './MediaViewerToolbar.scss';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import featureEnabled, { features } from '../../services/FeatureFlag';

const MediaViewerToolbar = ({
  middleSection,
  onClose,
  onPrevPage,
  onNextPage,
  attendeeReady,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="viewer_toolbar" className="viewer-toolbar">
      {onClose && (
        <div data-testid="viewer_close" className="viewer-toolbar-start">
          {/* eslint-disable-next-line */}
          <i className="icon-close with-i-hover f-small" onClick={onClose} />
        </div>
      )}

      {middleSection && (
        <div data-testid="viewer_middle_section">{middleSection()}</div>
      )}

      {featureEnabled(features.NEW_ATTENDEE_MGMT) && (
        <span data-testid="viewer_attendee_info">
          (
          {t('attendeeMediaReady', {
            ready: attendeeReady.ready,
            total: attendeeReady.total,
          })}
          )
        </span>
      )}

      {onPrevPage && onNextPage && (
        <div className="viewer-toolbar-end">
          {/* eslint-disable-next-line */}
          <i
            data-testid="viewer_prev_page"
            className="icon-arrow-left with-i-hover f-small"
            onClick={onPrevPage}
          />
          {/* eslint-disable-next-line */}
          <i
            data-testid="viewer_next_page"
            className="icon-arrow-right with-i-hover f-small"
            onClick={onNextPage}
          />
        </div>
      )}

      <ReactTooltip />
    </div>
  );
};

export default MediaViewerToolbar;
