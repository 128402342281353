import { forEach, keys, merge, reduce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AsyncButton, Loader } from '../../atoms';
import { apiClient } from '../../services/ApiClient';
import Store from '../../services/Store';
import styles from '../CloseButton.module.scss';
import './SampleFormGenerator.scss';
import { SampleItem } from './SampleItem';

const filterObjectByPropertyValue = (inputObject, condition) => {
  const result = reduce(
    inputObject,
    (res, value, key) => {
      if (condition(value)) {
        res[key] = value;
      }
      return res;
    },
    {}
  );
  return keys(result).length ? result : null;
};

const SampleFormGenerator = ({ roomId, attendees, onCancel, onFinish }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [samples, setSamples] = useState(null);
  const [summary, setSummary] = useState({});
  const [, setValiditySummary] = useState({});
  const [valid, setValid] = useState(false);

  const attendee = attendees && attendees[0];

  useEffect(() => {
    if (attendee) {
      setLoading(true);
      apiClient
        .samples(roomId, attendee.id)
        .then((result) => {
          setSamples(result);
        })
        .finally(() => setLoading(false));
    }
  }, [attendee, roomId]);

  const isFormValid = (nextState) => {
    let result = true;
    forEach(keys(nextState), (key) => {
      result = result && nextState[key];
    });
    return result;
  };

  const handleBatchQuantityChange = (sampleSummary, validitySummary) => {
    setSummary((prevState) => merge(prevState, sampleSummary));
    setValiditySummary((prevState) => {
      const nextState = merge(prevState, validitySummary);
      setValid(isFormValid(nextState));
      return nextState;
    });
  };

  const filterSummary = (summaryData) => {
    const result = {};
    forEach(keys(summaryData), (key) => {
      const filtered = filterObjectByPropertyValue(
        summaryData[key],
        (value) => value > 0
      );
      if (filtered) {
        result[key] = filtered;
      }
    });
    return result;
  };

  const onGenerateForm = () => {
    const samplesData = filterSummary(summary);
    const samplesKeys = keys(samplesData);

    if (!samplesKeys.length) {
      const message = t('sampleDrop_quantity_validation_error');
      toast(message, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        toastId: message,
      });
      return;
    }

    const givenSamples = samplesKeys.map((sampleKey) => {
      const sample = samplesData[sampleKey];
      const batchKeys = keys(sample);
      return {
        id: sampleKey,
        givenBatches: batchKeys.map((batchKey) => {
          return { id: batchKey, quantity: sample[batchKey] };
        }),
      };
    });

    const payload = {
      roomId,
      attendeeId: attendee.id,
      givenSamples,
    };

    apiClient.prepareSampleDrop(payload).then((res) => {
      onFinish({ ...res.data.prepareSampleDrop, name: t('sampleDrop') });
    });
  };

  const allowedSamples = (samples && samples.allowedSamples) || [];
  const samplesAvailable = allowedSamples.length > 0;
  const attendeesWithSampleDropSubmitted =
    (Store && Store.attendeesWithSampleDropSubmitted) || [];
  const isFormSubmittedForCurrentAttendee =
    attendeesWithSampleDropSubmitted.indexOf(attendee.id) >= 0;

  const renderFooter = (showSubmit) => {
    return (
      <div className="sfg-footer">
        <AsyncButton onClick={onCancel}>{t('cancel')}</AsyncButton>
        {showSubmit && (
          <AsyncButton
            className="yr-button--solid-primary"
            onClick={onGenerateForm}
            disabled={!valid}
          >
            {t('samplesGenerateForm')}
          </AsyncButton>
        )}
      </div>
    );
  };

  const renderErrorMessage = (key) => {
    return (
      <div className="sfg-list">
        <div className="row row-center">{t(key)}</div>
      </div>
    );
  };

  return (
    <div className={`sfg padding-bigger ${styles.container}`}>
      <button type="button" className={styles.closeButton} onClick={onCancel}>
        &#10006;
      </button>

      <div className="section-header section-header-small">
        {t('sampleDrop')}
      </div>

      <Loader loading={loading} />

      {/* Form was submitted previously */}
      {!loading && isFormSubmittedForCurrentAttendee && (
        <>
          {renderErrorMessage('sampleDropAlreadySubmitted')}
          {renderFooter(false)}
        </>
      )}

      {!loading && !isFormSubmittedForCurrentAttendee && (
        <>
          {/* No samples are available */}
          {!samplesAvailable && (
            <>
              {renderErrorMessage('noSamples')}
              {renderFooter(false)}
            </>
          )}

          {/* Happy case */}
          {samplesAvailable && (
            <>
              <div className="sfg-list">
                <div className="row title v-spacer-big">
                  <div className="priority">{t('samplesColumnTitle')}</div>
                  <div className="sfg-control">
                    {t('samplesColumnQuantity')}
                  </div>
                </div>
                {allowedSamples.map((sample, index) => (
                  <SampleItem
                    // eslint-disable-next-line
                    key={index}
                    sample={sample}
                    onChange={handleBatchQuantityChange}
                  />
                ))}
              </div>
              {renderFooter(true)}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SampleFormGenerator;
