import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SecondaryActions.scss';
import Notes from '../components/Notes';
import Settings from '../components/Settings';
import Logout from '../components/Logout';
import DisconnectClient from '../components/DisconnectClient';
import ClientInfo from '../components/ClientInfo';
import AttendeeManagement from '../components/AttendeeManagement';
import styles from '../components/CloseButton.module.scss';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
// import featureEnabled, {features} from "../services/FeatureFlag";

const SecondaryActions = ({ roomData, cleanParentStage }) => {
  const { t } = useTranslation();
  const container = useRef(null);
  const [notesVisible, setNotesVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [disconnectClientVisible, setDisconnectClientVisible] = useState(false);
  const [clientInfoVisible, setClientInfoVisible] = useState(false);
  const [attendeeManagementVisible, setAttendeeManagementVisible] =
    useState(false);

  const isContentVisible = () => {
    return (
      notesVisible ||
      settingsVisible ||
      logoutVisible ||
      disconnectClientVisible ||
      clientInfoVisible ||
      attendeeManagementVisible
    );
  };

  const handleToggleNotes = () => {
    setNotesVisible((prev) => !prev);
    setSettingsVisible(false);
    setLogoutVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setAttendeeManagementVisible(false);
  };

  const handleToggleSettings = () => {
    setNotesVisible(false);
    setSettingsVisible((prev) => !prev);
    setLogoutVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setAttendeeManagementVisible(false);
  };

  const handleToggleLogout = () => {
    setNotesVisible(false);
    setSettingsVisible(false);
    setLogoutVisible((prev) => !prev);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setAttendeeManagementVisible(false);
  };

  const cleanStage = () => {
    setNotesVisible(false);
    setSettingsVisible(false);
    setLogoutVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setAttendeeManagementVisible(false);

    if (cleanParentStage) {
      cleanParentStage();
    }
  };

  const handleDisconnectClient = () => {
    setNotesVisible(false);
    setSettingsVisible(false);
    setLogoutVisible(false);
    setDisconnectClientVisible((prev) => !prev);
    setClientInfoVisible(false);
    setAttendeeManagementVisible(false);
  };

  const handleClientInfo = () => {
    setNotesVisible(false);
    setSettingsVisible(false);
    setLogoutVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible((prev) => !prev);
    setAttendeeManagementVisible(false);
  };

  // const handleAttendeeManagement = () => {
  //     setNotesVisible(false);
  //     setSettingsVisible(false);
  //     setLogoutVisible(false);
  //     setDisconnectClientVisible(false);
  //     setClientInfoVisible(false);
  //     setAttendeeManagementVisible(prev => !prev);
  // };

  const onCloseAll = () => {
    setAttendeeManagementVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setNotesVisible(false);
    setSettingsVisible(false);
    setLogoutVisible(false);
  };

  const onCloseAllExceptNote = () => {
    setAttendeeManagementVisible(false);
    setDisconnectClientVisible(false);
    setClientInfoVisible(false);
    setSettingsVisible(false);
    setLogoutVisible(false);
  };

  useOnClickOutside(container, onCloseAllExceptNote);

  return (
    <div ref={container} className="secondary-actions">
      {isContentVisible() && (
        <div
          className={`secondary-actions-content card padding-bigger vw-25 ${styles.container}`}
        >
          <button
            type="button"
            className={styles.closeButton}
            onClick={onCloseAll}
          >
            &#10006;
          </button>
          {notesVisible && <Notes />}
          {attendeeManagementVisible && <AttendeeManagement />}
          {settingsVisible && <Settings onClose={handleToggleSettings} />}
          {logoutVisible && <Logout roomData={roomData} />}
          {disconnectClientVisible && (
            <DisconnectClient onClose={handleDisconnectClient} />
          )}
          {clientInfoVisible && (
            <ClientInfo roomData={roomData} onClose={handleClientInfo} />
          )}
        </div>
      )}

      <div ref={container} className="secondary-actions-side-bar card ">
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_chat"
          className="menu-icon menu-icon-disabled"
          data-tip={t('chat')}
          data-place="left"
          onClick={() => {}}
        >
          <i className="icon-chat" />
        </div>
        {/* #TODO: re-enable once new attendee management is mature. Currently crashes the client */}
        {/* and prevents the upgrade to react 17. */}
        {/* { */}
        {/*    featureEnabled(features.NEW_ATTENDEE_MGMT) && */}
        {/*    <div data-testid="menu_attendee_managment" */}
        {/*         className="menu-icon" */}
        {/*         data-tip={t("attendeeManagement")} data-place={'left'} */}
        {/*         onClick={handleAttendeeManagement}> */}
        {/*        <i className="icon-attendeemgmt"/> */}
        {/*    </div> */}
        {/* } */}
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_user_add"
          className="menu-icon"
          onClick={handleClientInfo}
          data-tip={t('clientInvite')}
          data-place="left"
        >
          <i className="icon-user-add" />
        </div>
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_user_remove"
          className="menu-icon"
          onClick={handleDisconnectClient}
          data-tip={t('disconnectClient')}
          data-place="left"
        >
          <i className="icon-user-remove" />
        </div>
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_notes"
          className="menu-icon"
          data-tip={t('notes')}
          data-place="left"
          onClick={handleToggleNotes}
        >
          <i className="icon-notes" />
        </div>
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_clean_stage"
          className="menu-icon"
          data-tip={t('cleanStage')}
          data-place="left"
          onClick={cleanStage}
        >
          <i className="icon-cleanstage" />
        </div>
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_settings"
          className="menu-icon"
          data-tip={t('settings')}
          data-place="left"
          onClick={handleToggleSettings}
        >
          <i className="icon-settings" />
        </div>
        {/* eslint-disable-next-line */}
        <div
          data-testid="menu_logout"
          className="menu-icon"
          data-tip={t('logout')}
          data-place="left"
          onClick={handleToggleLogout}
        >
          <i className="icon-sign-out" />
        </div>
      </div>
    </div>
  );
};

export default SecondaryActions;
