import { toast } from 'react-toastify';
import { getI18n } from 'react-i18next';
import io from 'socket.io-client';
import React from 'react';
import Store from './Store';
import { apiClient } from './ApiClient';
import SessionEventType from './SessionEventType';
import featureEnabled, { features } from './FeatureFlag';
import { getStateServerUrl } from '../utils';

class ComChannel {
  role;

  roomId;

  i18 = getI18n();

  roomInfo;

  attendees;

  label = {
    attendeeJoined: this.i18.t('attendeeJoined'),
    attendeeLeft: this.i18.t('attendeeLeft'),
    organizerJoined: this.i18.t('organizerJoined'),
    organizerLeft: this.i18.t('organizerLeft'),
    connected: this.i18.t('connected'),
    error: this.i18.t('tryingToConnect'),
  };

  constructor(
    role,
    attendees,
    roomId,
    roomCode,
    updateCurrentSubscriberCount,
    onReady,
    onError
  ) {
    if (!role || !roomId || !onReady || !onError) {
      throw Error('Invalid ComChannel initialization');
    }

    this.role = role;
    this.roomId = roomId;
    this.attendees = attendees;
    this.socket = io(getStateServerUrl(), {
      transports: ['websocket'],
      upgrade: false,
    });

    this.getSocket().on('connect', () => {
      this.getSocket().emit('room_join', {
        role,
        roomId,
        roomCode,
        organizerInfo: Store.tokenParsed,
        restricted: !featureEnabled(features.MULTI_ATTENDEES),
      });
    });

    this.getSocket().on('room_joined', (data) => {
      toast.dismiss();

      if (this.role === 'organizer') {
        this.broadcastOrganizerMediaClose({});
      }

      if (data.limitExceeded && role !== 'organizer') {
        onError({ message: 'r800', redirect: true });
        this.getSocket().disconnect();
        return;
      }
      this.roomInfo = data;

      if (this.role === 'attendee') {
        this.getSocket().on('organizer_connected', (event) => {
          const msg = event.connected
            ? this.label.organizerJoined
            : this.label.organizerLeft;
          toast(msg, {
            type: event.connected ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
            toastId: msg,
          });
          this.roomInfo = event;
        });
      } else if (this.role === 'organizer') {
        const eventData = {
          attendees,
          relatedSessionId: data.sessionId,
        };
        this.generateSessionEvent(SessionEventType.ORGANIZER_JOINED, eventData);

        this.getSocket().on('attendee_connected', (event) => {
          const msg = event.connected
            ? this.label.attendeeJoined
            : this.label.attendeeLeft;

          const type = event.connected
            ? SessionEventType.ATTENDEE_JOINED
            : SessionEventType.ATTENDEE_LEFT;
          this.generateSessionEvent(type, eventData);
          toast(msg, {
            type: event.connected ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
            toastId: msg,
          });

          let clientCount = 0;
          if (event && event.stat && event.stat.clientCount) {
            clientCount = event.stat.clientCount;
          }

          if (updateCurrentSubscriberCount) {
            updateCurrentSubscriberCount(clientCount - 1);
          }
        });
      }

      onReady(this);

      toast(this.label.connected, {
        type: toast.TYPE.SUCCESS,
        toastId: this.label.error,
      });
    });

    this.getSocket().on('disconnect', () => {
      //
    });

    this.getSocket().on('reconnect_attempt', () => {
      //
    });

    this.getSocket().on('connect_error', () => {
      toast(this.label.error, {
        type: toast.TYPE.ERROR,
        autoClose: false,
        closeOnClick: false,
        toastId: this.label.error,
      });
    });
  }

  getSocket() {
    return this.socket;
  }

  setRoomMetaData(event) {
    this.roomInfo.roomMetaData = event;
  }

  getRoomMetaData() {
    return this.roomInfo && this.roomInfo.roomMetaData;
  }

  getRoomInfo() {
    return this.roomInfo;
  }

  subscribeToEvent(event) {
    this.getSocket().on(event.name, event.callback);
    return () => this.getSocket().removeListener(event.name, event.callback);
  }

  emit(eventName, event) {
    this.getSocket().emit(eventName, event);
  }

  onSessionExpired(callback) {
    return this.subscribeToEvent({ name: 'session_expired', callback });
  }

  onMediaOpen(callback) {
    return this.subscribeToEvent({ name: 'organizer_media_opened', callback });
  }

  onMediaClose(callback) {
    return this.subscribeToEvent({ name: 'organizer_media_closed', callback });
  }

  onFormOpen(callback) {
    return this.subscribeToEvent({ name: 'organizer_form_opened', callback });
  }

  onFormClose(callback) {
    return this.subscribeToEvent({ name: 'organizer_form_closed', callback });
  }

  onPresentationStateChanged(callback) {
    return this.subscribeToEvent({
      name: 'organizer_presentation_state_changed',
      callback,
    });
  }

  onFormViewerValueChanged(callback) {
    return this.subscribeToEvent({
      name: 'form_viewer_value_changed',
      callback,
    });
  }

  onFormViewerAttendeeCanceled(callback) {
    return this.subscribeToEvent({
      name: 'attendee_form_canceled',
      callback,
    });
  }

  onFormViewerAttendeeSubmitted(callback) {
    return this.subscribeToEvent({
      name: 'attendee_form_submitted',
      callback,
    });
  }

  onOrganizerLoggedOut(callback) {
    return this.subscribeToEvent({
      name: 'organizer_logged_out',
      callback,
    });
  }

  onOrganizerTerminateSession(callback) {
    return this.subscribeToEvent({
      name: 'organizer_do_client_disconnected',
      callback,
    });
  }

  onPointerShown(callback) {
    return this.subscribeToEvent({
      name: 'pointer_shown',
      callback,
    });
  }

  onRoomMetaData(callback) {
    return this.subscribeToEvent({
      name: 'room_meta_data',
      callback,
    });
  }

  onAttendeeReadyOrganizerNotify(callback) {
    return this.subscribeToEvent({
      name: 'attendee_ready_organizer_notified',
      callback,
    });
  }

  broadcastOrganizerMediaOpen(event) {
    if (this.roomInfo) {
      const eventData = {
        attendees: this.attendees,
        formId: event ? event.id : undefined,
        relatedSessionId: this.roomInfo ? this.roomInfo.sessionId : undefined,
      };
      this.generateSessionEvent(SessionEventType.MEDIA_OPENED, eventData);
    }
    this.emit('organizer_media_open', event);
  }

  broadcastOrganizerMediaClose(event) {
    if (this.roomInfo) {
      const eventData = {
        attendees: this.attendees,
        formId: event ? event.id : undefined,
        relatedSessionId: this.roomInfo ? this.roomInfo.sessionId : undefined,
      };
      this.generateSessionEvent(SessionEventType.MEDIA_CLOSED, eventData);
    }
    this.emit('organizer_media_close', event);
  }

  broadcastOrganizerFormOpen(event) {
    const eventData = {
      attendees: this.attendees,
      formId: event ? event.id : undefined,
      relatedSessionId: this.roomInfo ? this.roomInfo.sessionId : undefined,
    };
    this.generateSessionEvent(SessionEventType.FORM_OPENED, eventData);
    this.emit('organizer_form_open', event);
  }

  broadcastOrganizerFormClose(event) {
    const eventData = {
      attendees: this.attendees,
      formId: event ? event.id : undefined,
      relatedSessionId: this.roomInfo ? this.roomInfo.sessionId : undefined,
    };
    this.generateSessionEvent(SessionEventType.FORM_CLOSED, eventData);
    this.emit('organizer_form_close', event);
  }

  broadcastAttendeeFormCancel(event) {
    this.emit('attendee_form_cancel', event);
  }

  broadcastAttendeeFormSubmit(event) {
    this.emit('attendee_form_submit', event);
  }

  broadcastOrganizerPresentationStateChange(event, mediaId) {
    const eventData = {
      attendees: this.attendees,
      mediaId,
      relatedSessionId: this.roomInfo ? this.roomInfo.sessionId : undefined,
      eventState: event,
    };
    this.generateSessionEvent(
      SessionEventType.MEDIA_PAGE_TRANSITION,
      eventData
    );
    this.emit('organizer_presentation_state_change', event);
  }

  broadcastFormViewerValueChange(event) {
    this.emit('form_viewer_value_change', event);
  }

  broadcastDisconnectClient() {
    this.emit('organizer_do_client_disconnect', {});
  }

  broadcastOrganizerLogOut() {
    this.emit('organizer_logout', {});
  }

  broadcastRoomMetaData(event) {
    this.emit('room_meta_data', event);
  }

  broadcastPointerShow(event) {
    this.emit('pointer_show', event);
  }

  attendeeReady(event) {
    this.emit('attendee_ready_for_media', event);
  }

  generateSessionEvent(type, eventData) {
    if (this.roomInfo) {
      return apiClient.addSessionEvent(this.roomInfo.roomId, type, eventData);
    }

    return null;
  }

  close() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

const initChannel = (
  role,
  attendees,
  roomId,
  roomCode,
  updateCurrentSubscriberCount
) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    new ComChannel(
      role,
      attendees,
      roomId,
      roomCode,
      updateCurrentSubscriberCount,
      (channel) => {
        resolve(channel);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const ComChannelContext = React.createContext(undefined);

export { initChannel, ComChannelContext };
export default ComChannel;
