class SessionEventType {
  ROOM_CREATED = 'ROOM_CREATED';

  ROOM_CLOSED = 'ROOM_CLOSED';

  ROOM_CANCELLED = 'ROOM_CANCELLED';

  ORGANIZER_JOINED = 'ORGANIZER_JOINED';

  ORGANIZER_LEFT = 'ORGANIZER_LEFT';

  ATTENDEE_JOINED = 'ATTENDEE_JOINED';

  ATTENDEE_LEFT = 'ATTENDEE_LEFT';

  MEDIA_OPENED = 'MEDIA_OPENED';

  MEDIA_CLOSED = 'MEDIA_CLOSED';

  MEDIA_PAGE_TRANSITION = 'MEDIA_PAGE_TRANSITION';

  FORM_OPENED = 'FORM_OPENED';

  FORM_CLOSED = 'FORM_CLOSED';

  FORM_SUBMITTED = 'FORM_SUBMITTED';
}

export default new SessionEventType();
