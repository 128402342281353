import { add, isAfter, isBefore } from 'date-fns';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Alert, AsyncButton, Label } from '../atoms';
import RoomCreation from '../components/RoomCreation';
import RoomsList from '../components/RoomsList';
import useRemoveLoader from '../components/useRemoveLoader';
import { format } from '../utils/date-fns';
import { apiClient } from '../services/ApiClient';
import featureEnabled, { features } from '../services/FeatureFlag';
import Store from '../services/Store';
import { getAdminUrl, getAttendeeUrl } from '../utils';
import './AppOrganizerLobby.scss';

const AppOrganizerRoom = ({ ...props }) => {
  const schedulingEnabled = featureEnabled(features.SCHEDULING);
  const { t } = useTranslation();
  const [version, setVersion] = useState(0);
  const [attendeeParam, setAttendeeParam] = useState();
  const [attendees, setAttendees] = useState();
  const [roomCreation, setRoomCreation] = useState(false);
  const [roomCancellation, setRoomCancellation] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [inProgressRooms, setInProgressRooms] = useState([]);
  const [plannedRooms, setPlannedRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const updateRoomsModel = (roomList) => {
    const time = add(new Date(), { hours: 1 });
    setRooms(roomList);
    setInProgressRooms(
      orderBy(
        filter(roomList, (room) => isBefore(new Date(room.startTime), time)),
        ['startTime'],
        ['asc']
      )
    );
    setPlannedRooms(
      orderBy(
        filter(roomList, (room) => isAfter(new Date(room.startTime), time)),
        ['startTime'],
        ['asc']
      )
    );
  };

  useRemoveLoader();

  useEffect(() => {
    const { attendee } = queryString.parse(props.location.search);
    if (attendee && attendee !== attendeeParam) {
      setAttendeeParam(attendee);
    }
  }, [props, attendeeParam]);

  useEffect(() => {
    const attendeeIds = attendeeParam ? attendeeParam.split(',') : [];
    apiClient.getAttendeeData(attendeeIds).then((attendee) => {
      setAttendees(attendee);
    });
  }, [attendeeParam]);

  useEffect(() => {
    apiClient.getRooms([], ['IN_PROGRESS']).then((roomsInProgress) => {
      updateRoomsModel(roomsInProgress);
    });
  }, [version]);

  const onRoomEnter = () => {
    props.history.replace(`/room/${selectedRoom.id}`);
  };

  const onRoomSelected = (room) => {
    setSelectedRoom(selectedRoom === room ? null : room);
  };

  const onRoomCreation = (room) => {
    updateRoomsModel([...rooms, room]);
    setSelectedRoom(room);
    setRoomCreation(false);
  };

  const onRoomCreationCancel = () => {
    setRoomCreation(false);
  };

  const onRoomCancel = async () => {
    await apiClient.cancelRoomMutation(selectedRoom.id);
    setRoomCancellation(false);
    setSelectedRoom(undefined);
    setVersion((prev) => prev + 1);
  };

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toast(t('copiedToClipboard'), {
      type: toast.TYPE.SUCCESS,
    });
  };

  const handleLogOut = () => {
    apiClient.logOutFromKeycloak();
  };

  const attendeesToString = () => {
    return selectedRoom.attendees && selectedRoom.attendees.length > 0
      ? attendees.map((it) => it.displayName).join(', ')
      : '-';
  };

  const renderOrganizerLink = () => {
    return renderLink(`${window.location.origin}/#/room/${selectedRoom.id}`);
  };

  const renderAttendeeLink = () => {
    // attendee-ui uses browser router, therefore no #/ in the URL
    return renderLink(`${getAttendeeUrl()}/?roomCode=${selectedRoom.code}`);
  };

  const renderLink = (text) => {
    return (
      <div className="row">
        {/* eslint-disable-next-line */}
        <i
          className="icon-circled-export icon-small h-spacer"
          onClick={() => copyToClipboard(text)}
        />
        {text}
      </div>
    );
  };

  const isAdmin = () => {
    return (
      Store.tokenParsed &&
      Store.tokenParsed.realm_access &&
      Store.tokenParsed.realm_access.roles &&
      Store.tokenParsed.realm_access.roles.find((it) => it === 'admin')
    );
  };

  const openAdmin = () => {
    window.open(getAdminUrl(), '_blank');
  };

  return (
    <div className="lobby">
      <div className="lobby-header">
        <i data-testid="lobby_logo" className="icon-logo" />
        <div className="lobby-header-sign-out">
          <Label keyId="welcome" />
          {Store.tokenParsed && `, ${Store.tokenParsed.name}`}{' '}
          {/* eslint-disable-next-line */}
          <i
            onClick={handleLogOut}
            data-testid="sign-out"
            className="padding-bigger icon-sign-out"
          />
        </div>
      </div>

      <div className="lobby-wrapper">
        <div className="lobby-content padding-big card card-plain">
          <div className="lobby-room-lists v-spacer-big">
            <div className="lobby-room-lists-content">
              <div className="lobby-room-lists-item">
                <div>
                  <Label keyId="inProgressRooms" />
                </div>
                <RoomsList
                  rooms={inProgressRooms}
                  selectedRoom={selectedRoom}
                  onRoomSelected={onRoomSelected}
                />
              </div>

              {schedulingEnabled && (
                <div className="lobby-room-lists-item">
                  <div>
                    <Label keyId="plannedRooms" />
                  </div>
                  <RoomsList
                    rooms={plannedRooms}
                    selectedRoom={selectedRoom}
                    onRoomSelected={onRoomSelected}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row row-end padding-big">
            {isAdmin() && (
              <>
                {/* eslint-disable-next-line */}
                <i
                  onClick={() => openAdmin()}
                  data-testid="go_to_admin"
                  className="color-accent icon-settings"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            <AsyncButton onClick={() => setRoomCreation(true)}>
              {t('createRoom')}
            </AsyncButton>
          </div>
        </div>

        {selectedRoom && (
          <div className="lobby-details card card-plain padding-bigger">
            <section className="lobby-details-content ">
              <div>
                {`${t('room')} ${selectedRoom.name} (#${selectedRoom.code})`}
              </div>
              <div>
                <Label keyId="startTime" size="small" />
                <div>{format(new Date(selectedRoom.startTime), 'PPPP p')}</div>
              </div>
              <div>
                <Label keyId="attendee" size="small" />
                <div>{attendeesToString()}</div>
              </div>
              <div>
                <Label keyId="organizerLink" size="small" />
                {renderOrganizerLink()}
              </div>
              <div>
                <Label keyId="attendeeLink" size="small" />
                {renderAttendeeLink()}
              </div>
            </section>
            <div className="lobby-details-footer">
              <AsyncButton onClick={onRoomEnter}>{t('joinRoom')}</AsyncButton>
              <AsyncButton
                onClick={() => setRoomCancellation(true)}
                className="button-error"
              >
                {t('cancelRoom')}
              </AsyncButton>
            </div>
          </div>
        )}
      </div>

      {roomCreation && (
        <Alert>
          <RoomCreation
            attendees={attendees}
            onRoomCreation={onRoomCreation}
            onRoomCreationCancel={onRoomCreationCancel}
          />
        </Alert>
      )}

      {roomCancellation && (
        <Alert>
          <div className="v-spacer-big">{t('roomCancelPrompt')}</div>
          <div className="row row-space-around">
            <AsyncButton onClick={onRoomCancel}>{t('ok')}</AsyncButton>
            <AsyncButton
              onClick={() => setRoomCancellation(false)}
              className="button-error"
            >
              {t('cancel')}
            </AsyncButton>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default AppOrganizerRoom;
