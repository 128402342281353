import React from 'react';
import './PanelView.scss';

const PanelView = ({ previousPanel, showHeader, panel, onClose, onOpen }) => {
  const renderBack = () => {
    if (previousPanel === undefined) {
      return null;
    }
    return (
      // eslint-disable-next-line
      <div onClick={handleClose} className="panel-stack-back">
        <i className="icon-arrow-left panel-stack-back-icon" />
      </div>
    );
  };

  const renderHeader = () => {
    if (!showHeader) {
      return null;
    }
    return (
      <div className="panel-stack-header">
        <span>{renderBack()}</span>
        <div>{panel.title}</div>
        <span />
      </div>
    );
  };

  const handleClose = () => onClose(panel);

  return (
    <div className="panel-stack-view">
      {renderHeader()}
      <panel.component
        openPanel={onOpen}
        closePanel={handleClose}
        {...panel.props}
      />
    </div>
  );
};

export default PanelView;
