import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Label, AsyncButton } from '../atoms';
import { apiClient } from '../services/ApiClient';

const ClientInfo = ({ roomData, onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const firstAttendee = useCallback(() => {
    return (
      roomData &&
      roomData.attendees &&
      roomData.attendees.length > 0 &&
      roomData.attendees[0]
    );
  }, [roomData]);

  useEffect(() => {
    const attendee = firstAttendee();
    if (attendee && attendee.attendeeData) {
      setEmail(attendee.attendeeData.email);
    }
  }, [firstAttendee, roomData]);

  const getClientUrl = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  };

  const getClientPassword = () => {
    return roomData.code;
  };

  const sendLink = () => {
    const attendee = firstAttendee();
    const id = attendee && attendee.id;
    return apiClient.sendLinkMutation(roomData.id, id, email).then(() => {
      toast(t('emailSent', { email }), {
        type: toast.TYPE.SUCCESS,
      });
      if (onClose) {
        onClose();
      }
    });
  };

  const isValidEmailFormat = () => {
    return email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <section className="column h100">
      <b>
        <Label keyId="clientInvite" />
      </b>
      <table data-testid="client_invite_info">
        <tbody>
          <tr>
            <td className="v-spacer">
              <Label keyId="clientURL" />
            </td>
          </tr>
          <tr>
            <td data-testid="client_url" className="wordBreakAll v-spacer">
              <b>{getClientUrl()}</b>
            </td>
          </tr>
          <tr>
            <td className="v-spacer">
              <Label keyId="clientPassword" />
            </td>
          </tr>
          <tr>
            <td
              data-testid="client_password"
              className="clientPassword v-spacer"
            >
              {getClientPassword()}
            </td>
          </tr>
          <tr>
            <td className="v-spacer">
              <Label keyId="clientSendEmail" />
            </td>
          </tr>
          <tr>
            <td colSpan="v-spacer">
              <input
                data-testid="client_email"
                type="text"
                value={email}
                onChange={handleEmailChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="column column-center column-space-around">
        <AsyncButton onClick={sendLink} disabled={!isValidEmailFormat()}>
          {t('sendEmail')}
        </AsyncButton>
      </div>
    </section>
  );
};

export default ClientInfo;
